import { everIdProp } from "EverAttribute/EverId";
import React from "react";
import clsx from "clsx";
import "./Icon.scss";
import { IconProps } from "components/Icon/IconProps";
import { getSizePx } from "util/css";
import * as IconTokens from "tokens/typescript/IconTokens";

export const DEFAULT_ICON_SIZE = getIconSize(IconTokens.DEFAULT_SIZE) as number;

/**
 * Returns the size of an icon with the given props. If the size is specified as a number or in
 * terms of px units, it is returned as a number. If the size is specified in some other form, it
 * is returned. If no size is specified, {@link DEFAULT_ICON_SIZE} is returned.
 *
 * Note that width takes precedence over height when determining the size of an icon (this is also
 * true in the icon component itself).
 *
 * @param size the size passed to your Icon component
 */
export function getIconSize(size: IconProps["size"]): number | string {
    size = size ?? DEFAULT_ICON_SIZE;
    if (typeof size !== "string") {
        // size is a number, return it
        return size;
    }
    return getSizePx(size) || size;
}

type ICON_SIZE_NAMES = "EXTRA_SMALL" | "SMALL" | "MEDIUM" | "LARGE" | "DEFAULT";
export const ICON_SIZE: Record<ICON_SIZE_NAMES, number> = {
    EXTRA_SMALL: getIconSize(IconTokens.EXTRA_SMALL_SIZE) as number,
    SMALL: getIconSize(IconTokens.SMALL_SIZE) as number,
    MEDIUM: getIconSize(IconTokens.MEDIUM_SIZE) as number,
    LARGE: getIconSize(IconTokens.LARGE_SIZE) as number,
    DEFAULT: DEFAULT_ICON_SIZE,
};

/**
 * A component to factor out the logic shared between SVG icons in our design system.
 * This component is invoked by the icons generated by SVGR.
 * These icons pass the generated inlined SVG to this component as an arrow function -> svg
 *
 * This reduces our bundle size and makes them easier to edit as this file can be edited
 * instead of having to edit an abstract syntax tree in svg-template.js.
 * Changes change be observed without having to regenerate all the icons.
 *
 * Note that the svgProps argument will be spread into the svg,
 * so it effectively sets the default props for the icons.
 */
export const BaseIcon: React.FC<
    IconProps & {
        svg: (props: IconProps) => JSX.Element;
    }
> = ({ svg, left = false, children, everId, className, size, ...props }) => {
    size = getIconSize(size);
    className = clsx("bb-icon", className, {
        "bb-icon--extra-small": typeof size === "number" && size <= ICON_SIZE.EXTRA_SMALL,
        "bb-icon--small":
            typeof size === "number" && size > ICON_SIZE.EXTRA_SMALL && size <= ICON_SIZE.SMALL,
        "bb-icon--medium":
            typeof size === "number" && size > ICON_SIZE.SMALL && size <= ICON_SIZE.MEDIUM,
        "bb-icon--large": typeof size === "number" && size > ICON_SIZE.MEDIUM,
        "bb-icon--with-text": children,
    });

    // These are effectively the default props for SVG icons in the design system
    const svgProps: IconProps & { height: IconProps["size"]; width: IconProps["size"] } = {
        role: "img",
        xmlns: "http://www.w3.org/2000/svg",
        ...everIdProp(everId),
        ...props,
        height: size,
        width: size,
        className,
    };

    if (children !== null && children !== undefined) {
        children = <div className={"bb-icon__content"}>{children}</div>;
        // Delete className so it's not passed to the SVG
        delete svgProps.className;
        svgProps["aria-hidden"] = true;
    } else {
        if (!props["aria-hidden"] && !props["aria-label"] && !props["aria-labelledby"]) {
            console.warn(
                "Icons that are not aria-hidden must have an aria-label or aria-labelledby",
            );
        }
        return svg(svgProps);
    }

    // We place the generated svg in a simple wrapping div
    // because Safari does not respect the bounding boxes of inline SVGs.
    // This causes issues when placing an outline around the SVG.
    return (
        <div className={className} aria-hidden={props["aria-hidden"]}>
            {left && children}
            <div className={"bb-icon__svg-wrapper"}>{svg(svgProps)}</div>
            {!left && children}
        </div>
    );
};
