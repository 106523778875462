import clsx from "clsx";
import { IconProps } from "components/Icon/IconProps";
import { Popover } from "components/Popover";
import {
    ColumnFilterProps,
    FilterButton,
    FilterPopoverFooter,
    FilterPopoverHeader,
    FilterPopoverPreview,
    FilterPopoverPreviewProps,
} from "components/Table/ColumnFilter/ColumnFilter";
import { Span } from "components/Text";
import { TextField } from "components/TextInput";
import { useBrandedCallback } from "hooks/useBranded";
import { useDateRange, useNumberRange, UseRangeResultInputProps } from "hooks/useRange";
import React, { ReactElement, RefObject, useEffect, useRef, useState } from "react";
import { DateFormat, formatDate } from "util/date";

export * from "./CheckboxColumnFilter";

type BaseRangeColumnFilterProps<T> = Pick<
    ColumnFilterProps<FilterRange<T>>,
    | "everId"
    | "showPopover"
    | "setShowPopover"
    | "popoverPlacement"
    | "popoverEverId"
    | "tooltipPlacement"
    | "filterValue"
    | "setFilterValue"
    | "setPreviewFilterValue"
> & {
    description: string;
    fromValue: T | null;
    fromInputValue: string;
    fromInputRef: RefObject<HTMLInputElement>;
    fromInputProps: UseRangeResultInputProps;
    toValue: T | null;
    toInputValue: string;
    toInputRef: RefObject<HTMLInputElement>;
    toInputProps: UseRangeResultInputProps;
    clearButtonRef: RefObject<HTMLButtonElement>;
    clear: () => void;
    minDisplay?: string;
    maxDisplay?: string;
    errorMessageIcon: ReactElement<IconProps> | null;
    rangeDescription: string;
    preview?: ReactElement<FilterPopoverPreviewProps>;
};

/**
 * A component that contains some shared elements of {@link NumberRangeColumnFilter} and
 * {@link DateRangeColumnFilter}.
 */
function BaseRangeColumnFilter<T>({
    everId,
    showPopover,
    setShowPopover,
    popoverPlacement,
    popoverEverId,
    tooltipPlacement,
    filterValue,
    setFilterValue,
    setPreviewFilterValue,
    description,
    fromValue,
    fromInputValue,
    fromInputRef,
    fromInputProps,
    toValue,
    toInputValue,
    toInputRef,
    toInputProps,
    clearButtonRef,
    clear,
    minDisplay,
    maxDisplay,
    errorMessageIcon,
    rangeDescription,
    preview,
}: BaseRangeColumnFilterProps<T>) {
    const buttonRef = useRef(null);
    return (
        <>
            <FilterButton
                everId={everId}
                ref={buttonRef}
                filterApplied={!!filterValue}
                descriptionText={description}
                showPopover={showPopover}
                setShowPopover={setShowPopover}
                tooltipPlacement={tooltipPlacement}
            />
            <Popover
                everId={popoverEverId}
                show={showPopover}
                setShow={setShowPopover}
                target={buttonRef}
                aria-label={description}
                renderOutsideParent={true}
                placement={popoverPlacement}
                footer={
                    <FilterPopoverFooter
                        onCancel={() => setShowPopover(false)}
                        onDelete={() => {
                            setShowPopover(false);
                            setFilterValue(undefined);
                        }}
                        onSave={() => {
                            setShowPopover(false);
                            setFilterValue({ from: fromValue, to: toValue });
                        }}
                        hasSavedValue={!!filterValue}
                        disableSave={!fromValue && !toValue}
                        applyCount={null}
                    />
                }
            >
                <div className={clsx("bb-column-filter", "bb-range-column-filter")}>
                    <FilterPopoverHeader
                        headingText={description}
                        disableClear={!fromInputValue && !toInputValue}
                        clearButtonRef={clearButtonRef}
                        onClear={() => {
                            setPreviewFilterValue(undefined);
                            fromInputRef.current?.focus();
                            clear();
                        }}
                    />
                    <div className={"bb-range-column-filter__content"}>
                        <div className={"bb-range-column-filter__fields"}>
                            <TextField
                                {...fromInputProps}
                                ref={fromInputRef}
                                label={
                                    <>
                                        <Span.Semibold>From </Span.Semibold>
                                        {minDisplay && (
                                            <Span className={"bb-text--color-secondary"}>
                                                (min: {minDisplay})
                                            </Span>
                                        )}
                                    </>
                                }
                            />
                            <TextField
                                {...toInputProps}
                                ref={toInputRef}
                                label={
                                    <>
                                        <Span.Semibold>To </Span.Semibold>
                                        {maxDisplay && (
                                            <Span className={"bb-text--color-secondary"}>
                                                (max: {maxDisplay})
                                            </Span>
                                        )}
                                    </>
                                }
                            />
                        </div>
                        {errorMessageIcon}
                        {!errorMessageIcon && (
                            <Span.Small
                                className={clsx(
                                    "bb-range-column-filter__range-description",
                                    "bb-text--color-secondary",
                                )}
                            >
                                {rangeDescription}
                            </Span.Small>
                        )}
                    </div>
                    {preview}
                </div>
            </Popover>
        </>
    );
}

export interface FilterRange<T> {
    from: T | null;
    to: T | null;
}

type RangeColumnFilterProps<T> = ColumnFilterProps<FilterRange<T>> & {
    /**
     * The smallest value in the dataset represented by the table for this column.
     * This value is displayed in the label of the "from" input.
     */
    minValue?: T;
    /**
     * The largest value in the dataset represented by the table for this column.
     * This value is displayed in the label of the "to" input.
     */
    maxValue?: T;
};

export type NumberRangeColumnFilterProps = RangeColumnFilterProps<number>;

export function NumberRangeColumnFilter({
    everId,
    columnName,
    filterValue,
    setFilterValue,
    previewCount,
    setPreviewFilterValue,
    previewLoading = false,
    otherActiveFilters,
    showPopover,
    setShowPopover: setShowPopoverProp,
    popoverPlacement,
    popoverEverId,
    tooltipPlacement,
    minValue,
    maxValue,
}: NumberRangeColumnFilterProps) {
    const fromInputRef = useRef<HTMLInputElement>(null);
    const toInputRef = useRef<HTMLInputElement>(null);
    const clearButtonRef = useRef<HTMLButtonElement>(null);
    const [rangeChanged, setRangeChanged] = useState<boolean>(false);
    const description = `Filter by ${columnName}`;

    const onValidate = useBrandedCallback(
        (from: number | null, to: number | null, error: boolean) => {
            if (!error) {
                setPreviewFilterValue({ from, to });
                if (from || to) {
                    setRangeChanged(true);
                }
            }
        },
        [setPreviewFilterValue],
    );

    const {
        fromInputProps,
        fromValue,
        fromInputValue,
        setFromInputValue,
        toInputProps,
        toValue,
        toInputValue,
        setToInputValue,
        errorMessageIcon,
        validate,
        clear,
    } = useNumberRange({
        fromInputRef,
        toInputRef,
        initialFrom: filterValue?.from || undefined,
        initialTo: filterValue?.to || undefined,
        clearButtonRef,
        onValidate,
    });

    const setShowPopover = (show: boolean) => {
        if (show && !showPopover) {
            validate();
        }
        setShowPopoverProp(show);
    };

    useEffect(() => {
        if (showPopover) {
            fromInputRef.current?.focus();
        } else {
            setFromInputValue(filterValue?.from?.toString() || "");
            setToInputValue(filterValue?.to?.toString() || "");
            setPreviewFilterValue(filterValue);
            setRangeChanged(false);
        }
        validate();
    }, [
        showPopover,
        filterValue,
        setFromInputValue,
        setToInputValue,
        setPreviewFilterValue,
        validate,
    ]);

    let rangeDescription;
    if (fromValue && toValue) {
        rangeDescription = `Filtering numbers from ${fromValue} to ${toValue}`;
    } else if (fromValue && !toValue) {
        rangeDescription = `Filtering numbers greater than or equal to ${fromValue}`;
    } else if (!fromValue && toValue) {
        rangeDescription = `Filtering numbers less than or equal to ${toValue}`;
    } else {
        rangeDescription = "Enter a value or range to filter";
    }

    return (
        <BaseRangeColumnFilter
            everId={everId}
            showPopover={showPopover}
            setShowPopover={setShowPopover}
            popoverPlacement={popoverPlacement}
            popoverEverId={popoverEverId}
            tooltipPlacement={tooltipPlacement}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            setPreviewFilterValue={setPreviewFilterValue}
            description={description}
            fromValue={fromValue}
            fromInputValue={fromInputValue}
            fromInputRef={fromInputRef}
            fromInputProps={fromInputProps}
            toValue={toValue}
            toInputValue={toInputValue}
            toInputRef={toInputRef}
            toInputProps={toInputProps}
            clearButtonRef={clearButtonRef}
            clear={clear}
            minDisplay={minValue?.toLocaleString()}
            maxDisplay={maxValue?.toLocaleString()}
            errorMessageIcon={errorMessageIcon}
            rangeDescription={rangeDescription}
            preview={
                (fromValue || toValue || rangeChanged) && !errorMessageIcon ? (
                    <FilterPopoverPreview
                        previewCount={previewCount}
                        previewLoading={previewLoading}
                        otherActiveFilters={otherActiveFilters}
                    />
                ) : undefined
            }
        />
    );
}

export function getNumberRangeFilterDisplay(filterValue?: FilterRange<number>): string {
    if (!filterValue) {
        return "";
    }
    if (filterValue.from && !filterValue.to) {
        return `>= ${filterValue.from.toLocaleString()}`;
    } else if (filterValue.to && !filterValue.from) {
        return `<= ${filterValue.to.toLocaleString()}`;
    } else if (filterValue.to && filterValue.from) {
        return `${filterValue.from.toLocaleString()} to ${filterValue.to.toLocaleString()}`;
    }
    return "";
}

export type DateRangeColumnFilterProps = RangeColumnFilterProps<Date> & {
    /**
     * The {@link DateFormat} to use for parsing the "from" and "to" input values.
     */
    dateFormat: DateFormat;
};

/**
 * A column filter that allows for filtering across a date range. Time not included.
 *
 * Note: This filter uses the local browser time zone. The {@link filterValue} "from" and "to"
 * values will be set to Date objects representing midnight of the inputted dates in the local
 * browser time. {@link minValue} and {@link maxValue} will be interpreted in the local browser
 * time as well. If you need to use a different time zone (e.g. the project time zone), use
 * the {@code DateRangeColumnFilterWithTimezone} wrapper component.
 *
 * TODO: Update to use date picker component for the "from" and "to" inputs once implemented.
 */
export function DateRangeColumnFilter({
    everId,
    columnName,
    filterValue,
    setFilterValue,
    previewCount,
    setPreviewFilterValue,
    previewLoading = false,
    otherActiveFilters,
    showPopover,
    setShowPopover: setShowPopoverProp,
    popoverPlacement,
    popoverEverId,
    tooltipPlacement,
    dateFormat,
    minValue,
    maxValue,
}: DateRangeColumnFilterProps) {
    const fromInputRef = useRef<HTMLInputElement>(null);
    const toInputRef = useRef<HTMLInputElement>(null);
    const clearButtonRef = useRef<HTMLButtonElement>(null);
    const [rangeChanged, setRangeChanged] = useState<boolean>(false);
    const description = `Filter by ${columnName}`;

    const onValidate = useBrandedCallback(
        (from: Date | null, to: Date | null, error: boolean) => {
            if (!error) {
                setPreviewFilterValue({ from, to });
                if (from || to) {
                    setRangeChanged(true);
                }
            }
        },
        [setPreviewFilterValue],
    );

    const {
        fromInputProps,
        fromValue,
        fromDisplay,
        fromInputValue,
        setFromInputValue,
        toInputProps,
        toValue,
        toDisplay,
        toInputValue,
        setToInputValue,
        errorMessageIcon,
        validate,
        clear,
    } = useDateRange({
        dateFormat,
        fromInputRef,
        toInputRef,
        initialFrom: filterValue?.from ? formatDate(filterValue.from, dateFormat) : undefined,
        initialTo: filterValue?.to ? formatDate(filterValue.to, dateFormat) : undefined,
        clearButtonRef,
        onValidate,
    });

    const setShowPopover = (show: boolean) => {
        if (show && !showPopover) {
            validate();
        }
        setShowPopoverProp(show);
    };

    useEffect(() => {
        if (showPopover) {
            fromInputRef.current?.focus();
        } else {
            setFromInputValue(filterValue?.from ? formatDate(filterValue.from, dateFormat) : "");
            setToInputValue(filterValue?.to ? formatDate(filterValue.to, dateFormat) : "");
            setPreviewFilterValue(filterValue);
            setRangeChanged(false);
        }
        validate();
    }, [
        dateFormat,
        showPopover,
        filterValue,
        setFromInputValue,
        setToInputValue,
        setPreviewFilterValue,
        validate,
    ]);

    let rangeDescription;
    if (fromValue && toValue) {
        rangeDescription = `Filtering dates from ${fromDisplay} to ${toDisplay}`;
    } else if (fromValue && !toValue) {
        rangeDescription = `Filtering dates on or after ${fromDisplay}`;
    } else if (!fromValue && toValue) {
        rangeDescription = `Filtering dates on or before ${toDisplay}`;
    } else {
        rangeDescription = "Enter a value or range to filter";
    }

    return (
        <BaseRangeColumnFilter
            everId={everId}
            showPopover={showPopover}
            setShowPopover={setShowPopover}
            popoverPlacement={popoverPlacement}
            popoverEverId={popoverEverId}
            tooltipPlacement={tooltipPlacement}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            setPreviewFilterValue={setPreviewFilterValue}
            description={description}
            fromValue={fromValue}
            fromInputValue={fromInputValue}
            fromInputRef={fromInputRef}
            fromInputProps={fromInputProps}
            toValue={toValue}
            toInputValue={toInputValue}
            toInputRef={toInputRef}
            toInputProps={toInputProps}
            clearButtonRef={clearButtonRef}
            clear={clear}
            minDisplay={minValue ? formatDate(minValue, dateFormat) : undefined}
            maxDisplay={maxValue ? formatDate(maxValue, dateFormat) : undefined}
            errorMessageIcon={errorMessageIcon}
            rangeDescription={rangeDescription}
            preview={
                (fromValue || toValue || rangeChanged) && !errorMessageIcon ? (
                    <FilterPopoverPreview
                        previewCount={previewCount}
                        previewLoading={previewLoading}
                        otherActiveFilters={otherActiveFilters}
                    />
                ) : undefined
            }
        />
    );
}

export function getDateRangeFilterDisplay(
    dateFormat: DateFormat,
    filterValue?: FilterRange<Date>,
): string {
    if (!filterValue) {
        return "";
    }
    if (filterValue.from && !filterValue.to) {
        return `on or after ${formatDate(filterValue.from, dateFormat)}`;
    } else if (filterValue.to && !filterValue.from) {
        return `on or before ${formatDate(filterValue.to, dateFormat)}`;
    } else if (filterValue.to && filterValue.from) {
        return `${formatDate(filterValue.from, dateFormat)} to ${formatDate(
            filterValue.to,
            dateFormat,
        )}`;
    }
    return "";
}
