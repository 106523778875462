import { Memo } from "hooks/useBranded";
import { useCallback, useEffect, useRef } from "react";

/**
 * Hook used to return focus to the previous element that had focus after e.g. a dialog closes.
 *
 * If focus should be returned to a different element than the previously focused element,
 * then use {@link getFocusReturnElement} to get the correct element.
 */
export function useReturnFocus(
    capturingFocus: boolean,
    getFocusReturnElement?: Memo<(previousFocusElement: HTMLElement | null) => HTMLElement | null>,
): void {
    const previousFocus = useRef<HTMLElement | null>(null);
    const previousCapturing = useRef<boolean>(capturingFocus);

    const focusChange = useCallback(() => {
        if (!capturingFocus) {
            previousFocus.current = document.activeElement as HTMLElement;
        }
    }, [capturingFocus]);

    const returnFocus = useCallback(() => {
        const focusElement = getFocusReturnElement
            ? getFocusReturnElement(previousFocus.current)
            : previousFocus.current;
        focusElement?.focus();
    }, [getFocusReturnElement]);

    useEffect(() => {
        if (!capturingFocus && previousCapturing.current) {
            returnFocus();
        }
        !capturingFocus && document.addEventListener("focusin", focusChange);
        previousCapturing.current = capturingFocus;
        return () => {
            document.removeEventListener("focusin", focusChange);
        };
    }, [capturingFocus, focusChange, returnFocus]);
}
