import { Num } from "core";
import { Memo, useBrandedCallback } from "hooks/useBranded";
import { useEventListener } from "hooks/useEventListener";
import { Dispatch, Ref, SetStateAction, useRef, useState } from "react";
import { useCombinedRef } from "hooks/useCombinedRef";

export enum ResizableSide {
    LEFT = "left",
    RIGHT = "right",
}

interface UseResizerProps<T extends Element> {
    /**
     * A ref to the element that is resizeable.
     */
    externalResizeableRef?: Ref<T>;
    /**
     * The min width of the element in pixels.
     */
    minWidth?: number;
    /**
     * The max width of the element in pixels.
     */
    maxWidth?: number;
    /**
     * A setter for the width state variable.
     */
    setWidth?: Memo<Dispatch<SetStateAction<number>>> | Memo<(width: number) => void>;
    /**
     * The side of the element that is resizable.
     */
    resizableSide: ResizableSide;
}

interface UseResizerResult<T extends Element> {
    /**
     * A ref that should be passed to the resizeable element.
     */
    resizeableElementRef: Ref<T>;
    /**
     * An onMouseDown event handler that should be passed to the element that is used to resize the
     * desired component.
     */
    resizerOnMouseDown: (e: React.MouseEvent) => void;
}

export function useResizer<T extends Element = Element>({
    externalResizeableRef,
    minWidth,
    maxWidth,
    setWidth,
    resizableSide,
}: UseResizerProps<T>): UseResizerResult<T> {
    const [mouseDown, setMouseDown] = useState(false);
    const internalRef = useRef<T>(null);
    const elementRef = useCombinedRef(externalResizeableRef, internalRef);
    const mouseMoveHandler = useBrandedCallback(
        (event: Event) => {
            if (setWidth && mouseDown && internalRef.current) {
                const mouseX = (event as MouseEvent).pageX;
                const newWidth =
                    resizableSide === ResizableSide.RIGHT
                        ? mouseX - internalRef.current.getBoundingClientRect().x
                        : internalRef.current.getBoundingClientRect().right - mouseX;
                setWidth(Num.clamp(newWidth, minWidth, maxWidth));
            }
        },
        [mouseDown, minWidth, maxWidth, resizableSide, setWidth],
    );
    // Possible performance issue here because this event will fire on any mousemove.
    useEventListener(document, "mousemove", mouseMoveHandler);

    const mouseUpHandler = useBrandedCallback(() => {
        if (mouseDown) {
            setMouseDown(false);
        }
    }, [mouseDown]);
    useEventListener(document, "mouseup", mouseUpHandler);

    return {
        resizeableElementRef: elementRef,
        resizerOnMouseDown: (event: React.MouseEvent) => {
            event.preventDefault(); // Prevent text selection while dragging
            setMouseDown(true);
        },
    };
}
