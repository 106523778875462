import { ListBoxOption, ListBoxSection, OptionId } from "components/Menu/ListBox/BaseListBox";

function isSectioned<T extends OptionId>(
    object: ListBoxOption<T>[] | ListBoxSection<T>[],
): object is ListBoxSection<T>[] {
    return object[0] && "options" in object[0];
}

function wrapSections<T extends OptionId>(
    items: ListBoxOption<T>[] | ListBoxSection<T>[],
): ListBoxSection<T>[] {
    if (isSectioned(items)) {
        return items;
    } else if (items.length) {
        return [{ options: items }];
    } else {
        return [];
    }
}

function matchesFilter<T extends OptionId>(option: ListBoxOption<T>, filter: string) {
    return !filter || option.name.toLowerCase().includes(filter);
}

const MAX_NESTING_LEVEL = 4;

export interface ListBoxOptionWithNesting<T extends OptionId>
    extends Omit<ListBoxOption<T>, "subOptions"> {
    nestingLevel?: number;
}

export interface FlattenedSection<T extends OptionId> extends Omit<ListBoxSection<T>, "options"> {
    options: ListBoxOptionWithNesting<T>[];
}

/**
 * Recursively flattens a {@link ListBoxOption} into a list of
 * {@link ListBoxOptionWithNesting}, stored in {@link accumulator}.
 */
export function getFlattenedSubOptions<T extends OptionId>(
    option: ListBoxOption<T>,
    accumulator: ListBoxOptionWithNesting<T>[],
    level: number,
): void {
    accumulator.push({ ...option, nestingLevel: level });
    if (!option.subOptions?.length) {
        return;
    }
    if (level < MAX_NESTING_LEVEL) {
        option.subOptions.forEach((subOption) => {
            getFlattenedSubOptions(subOption, accumulator, level + 1);
        });
    }
}

/**
 * Given a list of listbox options or sections, returns a list of sections with flattened options.
 */
function getFlattenedSections<T extends OptionId>(
    items: ListBoxOption<T>[] | ListBoxSection<T>[],
): FlattenedSection<T>[] {
    const sections: ListBoxSection<T>[] = wrapSections(items);
    return sections.map((section) => {
        const flattenedOptions: ListBoxOptionWithNesting<T>[] = [];
        section.options.forEach((opt) => {
            getFlattenedSubOptions(opt, flattenedOptions, 0);
        });
        return { heading: section.heading, options: flattenedOptions };
    });
}

export interface ItemUtilsResult<T extends OptionId> {
    /**
     * The filtered and flattened sections to display in the listbox.
     */
    filteredSections: FlattenedSection<T>[];
    /**
     * A list of option ids representing the order that options appear in {@link filteredSections}.
     */
    optionOrder: T[];
    /**
     * A map of option id to display string, which may be displayed in the text input of the
     * listbox.
     */
    optionIdToName: Map<T, string>;
    /**
     * The set of lowercase, trimmed names of all options represented in the given items (not just
     * the filtered ones).
     */
    optionNames: Set<string>;
}

/**
 * Given the listbox items and filter value, returns several data structures in the shape of
 * {@link ItemUtilsResult}, which support various listbox functionality.
 */
export function getItemUtils<T extends OptionId>(
    items: ListBoxOption<T>[] | ListBoxSection<T>[],
    filterValue?: string,
): ItemUtilsResult<T> {
    const sections = getFlattenedSections(items);
    const optionOrder: T[] = [];
    const optionIdToName = new Map<T, string>();
    const optionNames = new Set<string>();
    const filteredSections = sections
        .map((section) => {
            section.options.forEach((option) => {
                optionIdToName.set(option.id, option.name);
                optionNames.add(option.name.toLowerCase().trim());
            });
            const filteredOptions = filterValue
                ? section.options.filter((option) => matchesFilter(option, filterValue))
                : section.options;
            if (!filteredOptions.length) {
                return null;
            }
            filteredOptions.forEach((opt) => optionOrder.push(opt.id));
            return {
                ...section,
                options: filteredOptions,
            };
        })
        .filter((section) => !!section);
    return { filteredSections, optionOrder, optionIdToName, optionNames };
}
