import { everIdProp } from "EverAttribute/EverId";
import React, { FC, ReactNode } from "react";
import clsx from "clsx";
import "./SelectorGroup.scss";
import * as icons from "components/Icon";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import * as SpacingTokens from "tokens/typescript/SpacingTokens";
import { EverIdProp } from "util/type";

export interface SelectorGroupProps extends EverIdProp {
    /**
     * Class for the group.
     */
    className?: string;
    /**
     * Header for the group.
     */
    header?: ReactNode;
    /**
     * Display options horizontally. Use only when space is an issue.
     * May not be used with nesting, subLabels, or labels longer than 3 words.
     */
    horizontal?: boolean;
    /**
     * Display header in a column to the left of the options.
     * Refrain from using with horizontal layout.
     */
    twoColumn?: boolean;
    /**
     * Visually nest elements under the first one.
     */
    nested?: boolean;
    /**
     * Use in conjunction with nested to hide elements after the first.
     */
    hidden?: boolean;
    /**
     * If true, will display the selector group as an error, and show the provided errorMessage.
     */
    error?: boolean;
    /**
     * Optional error message underneath the subLabel.
     * The default message is "This field is required".
     */
    errorMessage?: ReactNode;
    children?: ReactNode;
}

/**
 * A grouping of selector elements in a list
 */
export const SelectorGroup: FC<SelectorGroupProps> = ({
    everId,
    className,
    children,
    header,
    horizontal = false,
    twoColumn = false,
    nested = false,
    hidden = false,
    error = false,
    errorMessage = "This field is required",
}) => {
    const selectorGroupClasses = clsx("bb-selector-group", className, {
        "bb-selector-group--two-column": twoColumn,
    });

    const childrenClasses = clsx("bb-selector-group__children", {
        "bb-selector-group--horizontal": horizontal,
        "bb-selector-group--nested": nested && !horizontal,
        "bb-selector-group--nested-hidden": nested && hidden,
    });

    const contents = (
        <>
            {header && <legend className={"bb-selector-group__header"}>{header}</legend>}
            <div>
                <div className={childrenClasses}>{children}</div>
                {error && errorMessage && (
                    <icons.AlertTriangle
                        className={"bb-selector-group__error-icon"}
                        style={{ marginLeft: SpacingTokens.SCALE_300 }}
                        color={ColorTokens.DANGER}
                        size={20}
                    >
                        <span>{errorMessage}</span>
                    </icons.AlertTriangle>
                )}
            </div>
        </>
    );

    return (
        <fieldset className={selectorGroupClasses} {...everIdProp(everId)}>
            {twoColumn ? (
                // Extra div needed for two-column layout because display: flex doesn't work
                // on <fieldset> elements
                <div className={"bb-selector-group__two-column-wrapper"}>{contents}</div>
            ) : (
                contents
            )}
        </fieldset>
    );
};
