import { Memo, useBrandedCallback, useBrandedState } from "hooks/useBranded";
import React, { useMemo } from "react";
import { BaseListBox, OptionId, SharedListBoxProps } from "./BaseListBox";
import "./ListBox.scss";
import { FlattenedSection, getItemUtils } from "./ListBoxUtil";

export interface ListBoxProps<T extends OptionId = OptionId> extends SharedListBoxProps<T> {
    /**
     * The id of the currently selected option, or null if no option is selected.
     */
    selected: T | null;
    /**
     * The function to call when an option is selected or unselected.
     */
    onSelect: Memo<(option: T | null) => void>;
}

export function ListBox<T extends OptionId = OptionId>({
    items,
    filterValue: filterValueProp,
    setFilterValue: setFilterValueProp,
    selected,
    onSelect,
    ...props
}: ListBoxProps<T>) {
    const [filterValueInternal, setFilterValueInternal] = useBrandedState<string>("");
    const filterValue =
        typeof filterValueProp === "string" && setFilterValueProp
            ? filterValueProp
            : filterValueInternal;
    const setFilterValue = setFilterValueProp || setFilterValueInternal;

    const { optionIdToName, ...itemUtilsResult } = useMemo(() => {
        return getItemUtils(items, filterValueInternal);
    }, [filterValueInternal, items]);
    const filteredSections = itemUtilsResult.filteredSections as Memo<FlattenedSection<T>[]>;
    const optionNames = itemUtilsResult.optionNames as Memo<Set<string>>;

    const onOptionClick = useBrandedCallback(
        (optionId: T) => {
            if (selected === optionId) {
                onSelect(null);
            } else {
                onSelect(optionId);
            }
        },
        [onSelect, selected],
    );

    return (
        <BaseListBox
            {...props}
            sections={filteredSections}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            selected={selected}
            optionNames={optionNames}
            onOptionClick={onOptionClick}
            isMulti={false}
            selectedInputDisplay={(selected !== null && optionIdToName.get(selected)) || ""}
        />
    );
}
