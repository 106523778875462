/**
 * Returns the given argument as an array, wrapping it if necessary. If it is already an array, then
 * it is returned as-is, without copying. Otherwise:
 *
 * - null, undefined: []
 * - anything else: a one-element array of the value
 */
import { NonNullish } from "util/type";

export function wrap<T>(value: T | T[] | null | undefined): T[] {
    if (value === null || value === undefined) {
        return [];
    }
    return value instanceof Array ? value : [value];
}

/**
 * Simple utility function to, given an array of values and some filter text, filter the given
 * values using the given filter text. A provided mapping function maps the values in the given
 * array to representative strings, which are then used to filter on.
 *
 * If no filter text is provided (i.e. the filter text is undefined, null, or empty), will simply
 * return the given values.
 *
 * By default, case-insensitive.
 *
 * @param values The values to filter
 * @param filterText The string to search for among the values
 * @param mapper A function which converts a given value to its string representation
 * @param caseSensitive Whether the search should be case-sensitive; default false.
 */
export function filter<T>(
    values: T[],
    filterText: string | undefined | null,
    mapper: (value: T, index: number, array: T[]) => string,
    caseSensitive = false,
): T[] {
    if (!filterText) {
        return values;
    }
    filterText = caseSensitive ? filterText : filterText.toLowerCase();
    return values.filter((v, i, a) => {
        let mapped = mapper(v, i, a);
        mapped = caseSensitive ? mapped : mapped.toLowerCase();
        return mapped.indexOf(filterText as string) >= 0;
    });
}

export function filterNonNullish<E>(arr: E[]): NonNullish<E>[] {
    return arr.filter((v) => v !== null && v !== undefined) as NonNullish<E>[];
}

/**
 * Returns a list of all indices between {@link current} and {@link lastSelected}, not including
 * {@link lastSelected}. Useful for getting the selected indices when the user shift-clicks
 * to select multiple rows/items in a table/list.
 */
export function getSelectedItemIndices(current: number, lastSelected: number): number[] {
    let start = Math.min(current, lastSelected);
    let end = Math.max(current, lastSelected);
    if (start === lastSelected) {
        start += 1;
    } else {
        end -= 1;
    }
    return [...Array(end - start + 1).keys()].map((i) => i + start);
}
