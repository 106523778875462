import * as CommonIcon from "components/Icon/CommonIcon";
import { IconProps } from "components/Icon/IconProps";
import { TextFieldProps, TextFieldWidth } from "components/TextInput/TextField";
import { isAfter } from "date-fns";
import { Memo, useBrandedCallback, useBrandedState } from "hooks/useBranded";
import { useLatest } from "hooks/useLatest";
import React, {
    Dispatch,
    ReactElement,
    RefObject,
    SetStateAction,
    useCallback,
    useId,
    useRef,
    useState,
} from "react";
import { DATE_FORMAT_PLACEHOLDER, DateFormat, formatDate, parseUsingFormat } from "util/date";

type UseRangeProps<T> = {
    /**
     * The ref of the "from" input.
     */
    fromInputRef?: RefObject<HTMLInputElement>;
    /**
     * The ref of the "to" input.
     */
    toInputRef?: RefObject<HTMLInputElement>;
    /**
     * The initial value for the "from" input.
     */
    initialFrom?: string;
    /**
     * The initial value for the "to" input.
     */
    initialTo?: string;
    /**
     * The ref of a button or element that should clear the inputs. If provided, clicking the
     * button will not cause the validate function to run.
     */
    clearButtonRef?: RefObject<HTMLElement>;
    /**
     * A function that takes a string value and returns the corresponding valid value, or null
     * if the input string is invalid.
     */
    validateSingle: Memo<(value: string) => T | null>;
    /**
     * The error message to display if either the "from" or "to" input values are invalid.
     */
    singleErrorMessage: string;
    /**
     * A function that takes a valid "from" and "to" value, and returns true if the range
     * is valid and false otherwise.
     */
    validateRange: Memo<(from: T, to: T) => boolean>;
    /**
     * The error message to display if the "from" and "to" values are individually valid
     * but the range is invalid.
     */
    rangeErrorMessage: string;
    /**
     * An optional function to call after validating.
     */
    onValidate?: Memo<(from: T | null, to: T | null, error: boolean) => void>;
};

export type UseRangeResultInputProps = Pick<
    TextFieldProps,
    | "value"
    | "label"
    | "width"
    | "placeholder"
    | "error"
    | "errorMessage"
    | "aria-errormessage"
    | "onBlur"
    | "onChange"
    | "onKeyDown"
> & { ref: RefObject<HTMLInputElement> };

type UseRangeResult<T> = {
    /**
     * The props that should be passed into the {@link TextField} component for the "from" input.
     * Display props like {@link TextFieldProps.label} or {@link TextFieldProps.width} can be
     * overwritten as needed.
     */
    fromInputProps: UseRangeResultInputProps;
    /**
     * The props that should be passed into the {@link TextField} component for the "to" input.
     * Display props like {@link TextFieldProps.label} or {@link TextFieldProps.width} can be
     * overwritten as needed.
     */
    toInputProps: UseRangeResultInputProps;
    /**
     * The validated value of the "from" input. If the inputted string is empty or there is
     * an error message, then this value will be null.
     */
    fromValue: Memo<T | null>;
    /**
     * The value state of the "from" input.
     */
    fromInputValue: string;
    /**
     * The setter for the {@link fromInputValue} state variable.
     */
    setFromInputValue: Dispatch<SetStateAction<string>>;
    /**
     * The validated value of the "to" input. If the inputted string is empty or there is
     * an error message, then this value will be null.
     */
    toValue: Memo<T | null>;
    /**
     * The value state of the "to" input.
     */
    toInputValue: string;
    /**
     * The setter for the {@link toInputValue} state variable.
     */
    setToInputValue: Dispatch<SetStateAction<string>>;
    /**
     * An error icon with an accompanying error message to display under the inputs.
     * This value is null if the inputted range is valid.
     */
    errorMessageIcon: ReactElement<IconProps> | null;
    /**
     * A function that validates the currently inputted range values and updates the state of
     * the hook accordingly. This function is called automatically when either input is blurred.
     */
    validate: Memo<() => void>;
    /**
     * A function that clears the inputs and error.
     */
    clear: Memo<() => void>;
};

function useRange<T>({
    fromInputRef: fromInputRefProp,
    toInputRef: toInputRefProp,
    initialFrom = "",
    initialTo = "",
    clearButtonRef,
    validateSingle,
    singleErrorMessage,
    validateRange,
    rangeErrorMessage,
    onValidate,
}: UseRangeProps<T>): UseRangeResult<T> {
    const getNewState = useCallback(
        (fromInput: string, toInput: string) => {
            const fromTrimmed = fromInput.trim();
            const toTrimmed = toInput.trim();
            const fromValue = fromTrimmed ? validateSingle(fromTrimmed) : null;
            const toValue = toTrimmed ? validateSingle(toTrimmed) : null;
            const fromError = !!fromTrimmed && fromValue === null;
            const toError = !!toTrimmed && toValue === null;
            let message: string | null = null;
            if (fromError || toError) {
                message = singleErrorMessage;
            } else if (
                fromValue !== null
                && toValue !== null
                && !validateRange(fromValue, toValue)
            ) {
                message = rangeErrorMessage;
            }
            return {
                fromInputValue: fromTrimmed,
                fromValue,
                fromError,
                toInputValue: toTrimmed,
                toValue,
                toError,
                errorMessage: message,
            };
        },
        [rangeErrorMessage, singleErrorMessage, validateRange, validateSingle],
    );

    const {
        fromInputValue: initialFromInputValue,
        fromValue: initialFromValue,
        fromError: initialFromError,
        toInputValue: initialToInputValue,
        toValue: initialToValue,
        toError: initialToError,
        errorMessage: initialError,
    } = getNewState(initialFrom, initialTo);

    const fromInputRefInternal = useRef(null);
    const fromInputRef = fromInputRefProp || fromInputRefInternal;
    const toInputRefInternal = useRef(null);
    const toInputRef = toInputRefProp || toInputRefInternal;

    const [fromInputValue, setFromInputValue] = useState<string>(initialFromInputValue);
    const fromInputValueRef = useLatest(fromInputValue);
    const [toInputValue, setToInputValue] = useState<string>(initialToInputValue);
    const toInputValueRef = useLatest(toInputValue);

    const [fromValue, setFromValue] = useBrandedState<T | null>(initialFromValue);
    const [toValue, setToValue] = useBrandedState<T | null>(initialToValue);

    const [errorMessage, setErrorMessage] = useState<string | null>(initialError);
    const [inputError, setInputError] = useState<[boolean, boolean]>([
        initialFromError,
        initialToError,
    ]);

    const errorMessageId = useId();
    const errorMessageIcon = errorMessage ? (
        <CommonIcon.ErrorTriangle size={20}>
            <span id={errorMessageId} className={"bb-text--color-danger"}>
                {errorMessage}
            </span>
        </CommonIcon.ErrorTriangle>
    ) : null;

    const validate = useBrandedCallback(() => {
        const {
            fromInputValue: newFromInputValue,
            fromValue: newFromValue,
            fromError: newFromError,
            toInputValue: newToInputValue,
            toValue: newToValue,
            toError: newToError,
            errorMessage: newErrorMessage,
        } = getNewState(fromInputValueRef.current, toInputValueRef.current);
        setFromInputValue(newFromInputValue);
        setToInputValue(newToInputValue);
        setFromValue(newErrorMessage ? null : newFromValue);
        setToValue(newErrorMessage ? null : newToValue);
        setErrorMessage(newErrorMessage);
        setInputError([newFromError, newToError]);
        onValidate?.(newFromValue, newToValue, !!newErrorMessage);
    }, [getNewState, fromInputValueRef, toInputValueRef, setFromValue, setToValue, onValidate]);

    const clear = useBrandedCallback(() => {
        setFromInputValue("");
        setToInputValue("");
        setFromValue(null);
        setToValue(null);
        setErrorMessage(null);
        setInputError([false, false]);
    }, [setFromValue, setToValue]);

    return {
        fromInputProps: {
            ref: fromInputRef,
            value: fromInputValue,
            label: "From",
            width: TextFieldWidth.FULL,
            error: inputError[0],
            errorMessage: "",
            "aria-errormessage": errorMessage ? errorMessageId : undefined,
            onBlur: (e) => {
                if (clearButtonRef?.current && e.relatedTarget === clearButtonRef.current) {
                    return;
                }
                validate();
            },
            onChange: (event) => {
                setFromInputValue(event.target.value);
            },
            onKeyDown: (event) => {
                event.key === "Enter" && fromInputRef.current?.blur();
            },
        },
        toInputProps: {
            ref: toInputRef,
            value: toInputValue,
            label: "To",
            width: TextFieldWidth.FULL,
            error: inputError[1],
            errorMessage: "",
            "aria-errormessage": errorMessage ? errorMessageId : undefined,
            onBlur: (e) => {
                if (clearButtonRef?.current && e.relatedTarget === clearButtonRef.current) {
                    return;
                }
                validate();
            },
            onChange: (event) => {
                setToInputValue(event.target.value);
            },
            onKeyDown: (event) => {
                event.key === "Enter" && toInputRef.current?.blur();
            },
        },
        fromValue,
        fromInputValue,
        setFromInputValue,
        toValue,
        toInputValue,
        setToInputValue,
        errorMessageIcon,
        validate,
        clear,
    };
}

const VALIDATE_SINGLE_NUMBER = ((value: string) => {
    const numValue = Number(value);
    return value && !isNaN(numValue) ? numValue : null;
}) as Memo<(value: string) => number | null>;

const VALIDATE_NUMBER_RANGE = ((from: number, to: number) => {
    return from <= to;
}) as Memo<(from: number, to: number) => boolean>;

export type UseNumberRangeProps = Omit<
    UseRangeProps<number>,
    | "initialFrom"
    | "initialTo"
    | "validateSingle"
    | "singleErrorMessage"
    | "validateRange"
    | "rangeErrorMessage"
> & {
    initialFrom?: string | number;
    initialTo?: string | number;
};

/**
 * A hook that handles input behavior and validation for a simple number range component.
 */
export function useNumberRange({
    fromInputRef,
    toInputRef,
    initialFrom = "",
    initialTo = "",
    clearButtonRef,
    onValidate,
}: UseNumberRangeProps = {}): UseRangeResult<number> {
    if (typeof initialFrom === "number") {
        initialFrom = initialFrom.toString();
    }
    if (typeof initialTo === "number") {
        initialTo = initialTo.toString();
    }
    const result = useRange<number>({
        fromInputRef,
        toInputRef,
        initialFrom,
        initialTo,
        clearButtonRef,
        validateSingle: VALIDATE_SINGLE_NUMBER,
        singleErrorMessage: "Invalid number range",
        validateRange: VALIDATE_NUMBER_RANGE,
        rangeErrorMessage: "Invalid number range",
        onValidate,
    });
    result.fromInputProps.placeholder = "#";
    result.toInputProps.placeholder = "#";
    return result;
}

const VALIDATE_DATE_RANGE = ((fromDate: Date, toDate: Date) => {
    return !isAfter(fromDate, toDate);
}) as Memo<(fromDate: Date, toDate: Date) => boolean>;

export type UseDateRangeProps = Omit<
    UseRangeProps<Date>,
    | "initialFrom"
    | "initialTo"
    | "validateSingle"
    | "singleErrorMessage"
    | "validateRange"
    | "rangeErrorMessage"
> & {
    /**
     * The {@link DateFormat} that should be used by both the "from" and "to" inputs.
     */
    dateFormat: DateFormat;
    /**
     * The initial string or Date value of the "from" input. If providing a Date object, the
     * Date should represent the desired date in the local browser time zone. See DateUtil.ts
     * for utility methods for converting between timezones.
     */
    initialFrom?: string | Date;
    /**
     * The initial string or Date value of the "to" input. If providing a Date object, the
     * Date should represent the desired date in the local browser time zone. See DateUtil.ts
     * for utility methods for converting between timezones.
     */
    initialTo?: string | Date;
};

export type UseDateRangeResult = UseRangeResult<Date> & {
    /**
     * A Date object representing the value of the "from" input.
     *
     * The Date object represents midnight in the local browser time of the inputted date. Any
     * time zone manipulations must be handled separately. See DateUtil.ts for utility methods
     * for converting between timezones.
     *
     * If the inputted date string is empty or there is an error message, then this value
     * will be null.
     */
    fromValue: Memo<Date | null>;
    /**
     * A Date object representing the value of the "to" input.
     *
     * The Date object represents midnight in the local browser time of the inputted date. Any
     * time zone manipulations must be handled separately. See DateUtil.ts for utility methods
     * for converting between timezones.
     *
     * If the inputted date string is empty or there is an error message, then this value
     * will be null.
     */
    toValue: Memo<Date | null>;
    /**
     * The formatted display of the "from" date, using {@link UseDateRangeProps.dateFormat}.
     */
    fromDisplay: string | null;
    /**
     * The formatted display of the "to" date, using {@link UseDateRangeProps.dateFormat}.
     */
    toDisplay: string | null;
};

/**
 * A hook that handles date input behavior and validation for a simple date range component.
 *
 * Note: This hook uses the local browser time zone. The returned `fromValue` and `toValue`
 * will be set to Date objects representing midnight of the inputted dates in the local
 * browser time. {@link initialFrom} and {@link initialTo} will be interpreted in the local browser
 * time as well. If you need to use a different time zone (e.g. the project time zone), use
 * the {@code useDateRangeWithTimezone} wrapper hook.
 *
 * TODO: Add options for datetime and time only. Also add calendar widgets when available.
 */
export function useDateRange({
    dateFormat,
    fromInputRef,
    toInputRef,
    initialFrom = "",
    initialTo = "",
    clearButtonRef,
    onValidate,
}: UseDateRangeProps): UseDateRangeResult {
    const validateSingleDate = useBrandedCallback(
        (value: string) => {
            return value ? parseUsingFormat(value, dateFormat) : null;
        },
        [dateFormat],
    );
    if (initialFrom instanceof Date) {
        initialFrom = formatDate(initialFrom, dateFormat);
    }
    if (initialTo instanceof Date) {
        initialTo = formatDate(initialTo, dateFormat);
    }
    const result = useRange<Date>({
        fromInputRef,
        toInputRef,
        initialFrom,
        initialTo,
        clearButtonRef,
        validateSingle: validateSingleDate,
        singleErrorMessage: `Dates must be in ${DATE_FORMAT_PLACEHOLDER[dateFormat]} format`,
        validateRange: VALIDATE_DATE_RANGE,
        rangeErrorMessage: "Invalid date range",
        onValidate,
    });
    result.fromInputProps.placeholder = DATE_FORMAT_PLACEHOLDER[dateFormat];
    result.toInputProps.placeholder = DATE_FORMAT_PLACEHOLDER[dateFormat];
    return {
        ...result,
        fromDisplay: result.fromValue ? formatDate(result.fromValue, dateFormat) : null,
        toDisplay: result.toValue ? formatDate(result.toValue, dateFormat) : null,
    };
}
