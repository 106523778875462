import { Span } from "components/Text";
import { TooltipProps } from "components/Tooltip";
import React, {
    CSSProperties,
    KeyboardEventHandler,
    ReactElement,
    ReactNode,
    RefObject,
} from "react";
import * as IconTokens from "tokens/typescript/IconTokens";
import * as SpacingTokens from "tokens/typescript/SpacingTokens";
import { getSizePx } from "util/css";

export interface SharedMenuItemProps {
    /**
     * The content to place on the right-hand side of the item label. String values will be
     * automatically styled and ellipsed if necessary.
     */
    rightContent?: ReactNode;
    /**
     * The width of the div containing the {@link rightContent}.
     */
    rightContentWidth?: number;
    /**
     * A custom tooltip to render on the menu item when label content is ellipsified. Only use
     * this prop if the default ellipsification tooltip doesn't format label content properly.
     */
    ellipsificationTooltip?: ReactElement<TooltipProps>;
    /**
     * The nesting level of this checkbox in a dropdown menu or listbox, which determines the
     * level of indentation.
     */
    nestingLevel?: number;
    /**
     * Whether the item should be tab-focusable. Default true.
     */
    tabFocusable?: boolean;
}

const NESTING_SPACER_WIDTH = getSizePx(IconTokens.SMALL_SIZE) + getSizePx(SpacingTokens.SCALE_100);

/**
 * A spacer component for indenting menu items.
 * @param nestingLevel The nesting level of the menu item, which determines the size of the spacer.
 */
export function NestingSpacer({ nestingLevel }: { nestingLevel: number }) {
    const styleProps = {
        "--bb-menuItem-spacer-width": nestingLevel * NESTING_SPACER_WIDTH + "px",
    } as CSSProperties;
    return <div className={"bb-menu-item__spacer"} style={styleProps} />;
}

export function onItemInputKeyUp(
    inputRef: RefObject<HTMLInputElement>,
    outerOnKeyUp: KeyboardEventHandler<HTMLInputElement> | undefined,
): KeyboardEventHandler<HTMLInputElement> {
    return (event) => {
        if (event.key === "Enter") {
            inputRef.current?.click();
            // This prevents popovers inside of forms from submitting the form when selecting an
            // option using "Enter". This mirrors the behavior of HTML <select> elements.
            event.stopPropagation();
        }
        outerOnKeyUp?.(event);
    };
}

/**
 * Returns the tooltip content to display when the menu item is ellipsed.
 */
export function labelTooltipContent({
    label,
    subLabel,
    rightContent,
}: {
    label?: ReactNode;
    subLabel?: ReactNode;
    rightContent?: ReactNode;
}): ReactNode {
    return label ? (
        <>
            <Span>{label}</Span>
            {typeof rightContent === "string" && (
                <> [{<Span.Italic>{rightContent}</Span.Italic>}]</>
            )}
            {subLabel && <div>{subLabel}</div>}
        </>
    ) : undefined;
}
