import React, { CSSProperties, forwardRef, useLayoutEffect, useRef, useState } from "react";
import clsx from "clsx";
import { CSSTransition } from "react-transition-group";

import "./Expandable.scss";
import { SEC } from "util/constants";
import { FFC } from "util/type";

/**
 * Valid orientations for an {@link Expandable}.
 */
export enum ExpandableOrientation {
    VERTICAL = "vertical",
    HORIZONTAL = "horizontal",
}

export interface ExpandableProps {
    /**
     * An id to apply to the expandable content.
     *
     * The `aria-controls` property of the element controlling this expandable should be set
     * to this id. Additionally, the controlling element should have `aria-expanded` set to
     * true or false depending on whether this expandable is currently expanded.
     */
    id: string;
    /**
     * An optional class name to apply to the expandable content.
     *
     * Note that vertical padding should be applied to the child element rather than directly
     * to the top-level expandable div. Not doing so can cause a choppy animation due to how
     * the padding is displayed.
     */
    className?: string;
    /**
     * Style for the expandable element.
     */
    style?: CSSProperties;
    /**
     * Whether the expandable should expand vertically or horizontally.
     * Defaults to {@link ExpandableOrientation.VERTICAL}.
     */
    orientation?: ExpandableOrientation;
    /**
     * An optional boolean that, when set to false, always shows the expandable content
     * regardless of the value of the "expanded" prop. Default true.
     */
    expandable?: boolean;
    /**
     * A boolean indicating whether the expandable content should be expanded or collapsed. Is not
     * used if the "expandable" prop is explicitly set to false.
     */
    expanded: boolean;
    /**
     * An optional time value in milliseconds indicating the duration of the animation.
     * Default is 500ms.
     */
    transitionTime?: number;
    /**
     * The function to call when the expand/collapse animation has completed.
     */
    onTransitionComplete?: (expanded: boolean) => void;
    /**
     * An optional callback to be called if the contents of the expandable change.
     * @param heightOrWidth The new height or width of the expandable content
     */
    onSetHeightOrWidth?: (heightOrWidth: number) => void;
    /**
     * The id of another element whose text content provides an accessible name for the expandable.
     */
    "aria-labelledby"?: string;
    /**
     * The aria-modal value of the expandable.
     */
    "aria-modal"?: boolean;
    /**
     * The Aria role for the expandable.
     */
    role?: string;
    /**
     * The expandable content.
     */
    children: React.ReactNode;
}

// Necessary for type completeness when passing values through CSS
interface ExpandableCSS extends CSSProperties {
    "--expandableHeight": string;
    "--expandableWidth": string;
    "--transitionTime": string;
}

/**
 * A component used to show/hide content using "expand/collapse" animation. If the "expanded" prop
 * changes from false to true, the content's height will transition from 0 (hidden) to its full
 * height. If the "expanded" prop changes from true to false, the content's height will transition
 * from its full height to 0.
 */
export const Expandable: FFC<HTMLDivElement, ExpandableProps> = forwardRef(
    (
        {
            children,
            id,
            className,
            style,
            orientation = ExpandableOrientation.VERTICAL,
            expandable = true,
            expanded,
            transitionTime = 500,
            onTransitionComplete,
            onSetHeightOrWidth,
            "aria-labelledby": ariaLabelledBy,
            "aria-modal": ariaModal,
            role,
        },
        ref,
    ) => {
        if (!expandable) {
            // if expandable is false, we want to be expanded all the time
            expanded = true;
        }

        const [heightOrWidth, setHeightOrWidth] = useState(0);
        const [fullyCollapsed, setFullyCollapsed] = useState(!expanded);

        const classes = clsx("bb-expandable", className, `bb-expandable--${orientation}`, {
            "bb-expandable--collapsed": fullyCollapsed,
            "bb-expandable--expanded": !fullyCollapsed,
        });
        const contentRef = useRef<HTMLDivElement>(null);
        const cssVars: ExpandableCSS = {
            "--expandableHeight": heightOrWidth + "px",
            "--expandableWidth": heightOrWidth + "px",
            "--transitionTime": transitionTime / SEC + "s",
            ...style,
        };

        useLayoutEffect(() => {
            if (contentRef.current) {
                const newHeightOrWidth =
                    orientation === ExpandableOrientation.VERTICAL
                        ? contentRef.current.offsetHeight
                        : contentRef.current.offsetWidth;
                setHeightOrWidth(newHeightOrWidth);
                onSetHeightOrWidth?.(newHeightOrWidth);
            }
        }, [onSetHeightOrWidth, orientation, children]);

        return (
            <CSSTransition
                in={expanded}
                timeout={transitionTime}
                classNames={"bb-expandable-"}
                onExited={() => {
                    setFullyCollapsed(true);
                    onTransitionComplete?.(false);
                }}
                onEntered={() => {
                    setFullyCollapsed(false);
                    onTransitionComplete?.(true);
                }}
            >
                <div
                    id={id}
                    ref={ref}
                    className={classes}
                    aria-labelledby={ariaLabelledBy}
                    role={role}
                    aria-modal={ariaModal}
                    style={cssVars}
                >
                    <div ref={contentRef}>{children}</div>
                </div>
            </CSSTransition>
        );
    },
);
Expandable.displayName = "Expandable";
