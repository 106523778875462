import clsx from "clsx";
import { Check } from "components/Icon";
import { IconProps } from "components/Icon/IconProps";
import { Item, MenuItemProps } from "components/Menu/Item/Item";
import {
    labelTooltipContent,
    NestingSpacer,
    onItemInputKeyUp,
    SharedMenuItemProps,
} from "components/Menu/Item/ItemUtil";
import { TagProps } from "components/Tag";
import { Tooltip, TooltipProps } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, {
    CSSProperties,
    DetailedHTMLProps,
    FC,
    forwardRef,
    InputHTMLAttributes,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    RefCallback,
    RefObject,
    useId,
    useRef,
} from "react";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import { EverColor } from "tokens/typescript/EverColor";
import * as SpacingTokens from "tokens/typescript/SpacingTokens";
import { getSizePx } from "util/css";
import { Complete, EverIdProp, FFC } from "util/type";
import "./Option.scss";

const OPTION_MAIN_LABEL_CLASS = "bb-popover-menu__option-main-label";
const OPTION_MAIN_LABEL_TEXT_CLASS = "bb-popover-menu__option-main-label-text";
const OPTION_SUBLABEL_CLASS = "bb-popover-menu__option-sublabel";
const OPTION_LABEL_RIGHT_CLASS = "bb-popover-menu__option-label-right";

export enum MenuOptionType {
    SELECTABLE = "selectable-type",
    BUTTON = "button-type",
    LISTBOX_OPTION = "listbox-option-type",
    INFO = "info-type",
}

export interface MenuOptionProps
    extends EverIdProp,
        SharedMenuItemProps,
        Pick<MenuItemProps, "href" | "link" | "className"> {
    /**
     * The color for the option.
     */
    color?: EverColor | string;
    /**
     * Whether this option is disabled. Will still be tab-focusable.
     */
    disabled?: boolean;
    /**
     * A tooltip to render on the menu option. If not provided, a tooltip will be rendered
     * with the label content when the label is ellipsed.
     */
    tooltip?: ReactElement<TooltipProps>;
    /**
     * A custom tooltip to render on the menu item when label content is ellipsified. Only use
     * this prop if the default ellipsification tooltip doesn't format label content properly.
     */
    ellipsificationTooltip?: ReactElement<TooltipProps>;
    /**
     * If true, ellipsifies the option label and/or sub label if they cannot fit on a single line.
     * Display tooltip containing the full label and sub label on hover.
     */
    ellipsify?: boolean;
    /**
     * Optional icon to display to the left of the label. Width and height will be overridden to
     * be 20. Aria hidden will be overridden to be true.
     */
    icon?: ReactElement<IconProps>;
    /**
     * Label of the option.
     */
    label?: ReactNode;
    /**
     * Actions to fire when the option is clicked.
     * The same setShow function passed to PopoverMenu should be invoked here if clicking the
     * option is expected to close the menu.
     */
    onClick?: MouseEventHandler<HTMLInputElement>;
    /**
     * Whether this option has been selected. Only applicable for selectable options. Should not be
     * used for repeatable actions.
     */
    selected?: boolean;
    /**
     * Sublabel of the option.
     */
    subLabel?: ReactNode;
    /**
     * A tag to display to the right of the main label content.
     */
    tag?: ReactElement<TagProps>;
    /**
     * The type of menu option. Defaults to BUTTON.
     */
    type?: MenuOptionType;
    /**
     * A ref to apply to the input element.
     */
    inputRef?: RefObject<HTMLInputElement> | RefCallback<HTMLInputElement>;
}

type OptionInputProps = Complete<
    Pick<MenuOptionProps, "disabled" | "onClick" | "type" | "selected" | "tabFocusable">
> & {
    inputId: string;
};

const OptionInput: FFC<HTMLInputElement, OptionInputProps> = forwardRef(
    ({ disabled, onClick, type, selected, inputId, tabFocusable }, ref) => {
        // Input attributes that are the same for both selectable and unselectable input elements.
        const commonInputAttributes: DetailedHTMLProps<
            InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        > = {
            id: inputId,
            className: "bb-popover-menu__option-input",
            onKeyUp: onItemInputKeyUp(ref as RefObject<HTMLInputElement>, undefined),
            // React complains when you have an input with a value without an onChange about
            // this being an uncontrolled component.
            // We control this component through the onClick instead of the onChange, so we manually
            // specify a noop onChange to suppress this warning.
            onChange: () => {},
            onClick: disabled ? undefined : onClick,
            "aria-disabled": disabled || !onClick,
            tabIndex: tabFocusable ? 0 : -1,
            ref,
        };
        switch (type) {
            case MenuOptionType.BUTTON:
                return <input {...commonInputAttributes} type={"button"} role={"menuitem"} />;
            case MenuOptionType.SELECTABLE:
                return (
                    <input
                        {...commonInputAttributes}
                        type={"checkbox"}
                        role={"menuitemcheckbox"}
                        checked={selected}
                        aria-checked={selected}
                    />
                );
            case MenuOptionType.LISTBOX_OPTION:
                return (
                    <input
                        {...commonInputAttributes}
                        type={"checkbox"}
                        role={"option"}
                        checked={selected}
                        aria-checked={selected}
                        aria-selected={selected}
                    />
                );
            case MenuOptionType.INFO:
            default:
                return <></>;
        }
    },
);
OptionInput.displayName = "OptionInput";

/**
 * An option of a popover menu that is not a checkbox.
 * The standard checkbox and checkboxGroup should be used without any changes in PopoverMenus.
 */
export const Option: FC<MenuOptionProps> = ({
    selected = false,
    disabled = false,
    onClick,
    label,
    subLabel,
    icon,
    rightContent,
    rightContentWidth,
    tag,
    href,
    link,
    ellipsify = false,
    type = MenuOptionType.BUTTON,
    tabFocusable = true,
    tooltip,
    ellipsificationTooltip,
    nestingLevel,
    color,
    everId,
    className,
}) => {
    const selectable = type === MenuOptionType.SELECTABLE || type === MenuOptionType.LISTBOX_OPTION;
    selected &&= selectable;
    href = !selectable ? href : undefined;
    const hasIcon = !!icon;
    const inputId = useId();
    const inputRef = useRef<HTMLInputElement>(null);
    const optionClasses = clsx(
        className,
        "bb-popover-menu__option",
        `bb-popover-menu__option--${type}`,
        {
            "bb-popover-menu__option--selectable": selectable,
            "bb-popover-menu__option--selected": selected,
            "bb-popover-menu__option--disabled": disabled,
            "bb-popover-menu__option--has-icon": hasIcon,
        },
    );
    if (icon) {
        icon = React.cloneElement(icon, {
            className: clsx("bb-popover-menu__option-label-icon", icon.props.className),
            size: 20,
            "aria-hidden": true,
        });
    } else if (selected) {
        icon = (
            <Check
                size={20}
                color={ColorTokens.INTERACTIVE}
                className={"bb-popover-menu__option-label-check"}
                aria-hidden={true}
            />
        );
    }

    tag &&= React.cloneElement(tag, {
        className: clsx("bb-popover-menu__option-tag", tag.props.className),
    });

    const [labelRef, labelEntry] = useResizeObserver<HTMLElement>();
    const labelContent = labelTooltipContent({ label, subLabel, rightContent });
    const itemWidth = labelEntry.contentRect.width;
    if (!tooltip && itemWidth && (labelContent || ellipsificationTooltip) && ellipsify) {
        const mainLabelElement =
            labelEntry.target?.getElementsByClassName(OPTION_MAIN_LABEL_CLASS)[0];
        const mainLabelTextElement = labelEntry.target?.getElementsByClassName(
            OPTION_MAIN_LABEL_TEXT_CLASS,
        )[0];
        const subLabelElement = labelEntry.target?.getElementsByClassName(OPTION_SUBLABEL_CLASS)[0];
        const labelRightElement =
            labelEntry.target?.getElementsByClassName(OPTION_LABEL_RIGHT_CLASS)[0];
        // Because of how the tag is placed inside the main label, mainLabelTextElement
        // overflows instead of mainLabelElement when the tag is present. Otherwise,
        // mainLabelElement overflows.
        if (
            (!!mainLabelElement && mainLabelElement.scrollWidth > mainLabelElement.clientWidth)
            || (!!mainLabelTextElement
                && mainLabelTextElement.scrollWidth > mainLabelTextElement.clientWidth)
            || (!!subLabelElement && subLabelElement.scrollWidth > subLabelElement.clientWidth)
            || (!!labelRightElement
                && labelRightElement.scrollWidth > labelRightElement.clientWidth)
        ) {
            tooltip = ellipsificationTooltip || (
                <Tooltip aria-hidden={true}>{labelContent}</Tooltip>
            );
        }
    }

    const labelWrapperContent = (
        <>
            <div className={"bb-popover-menu__option-label-left"}>
                {!!nestingLevel && <NestingSpacer nestingLevel={nestingLevel} />}
                {icon}
                <div className={"bb-popover-menu__option-label-content"}>
                    <div className={OPTION_MAIN_LABEL_CLASS}>
                        <span className={OPTION_MAIN_LABEL_TEXT_CLASS}>{label}</span>
                        {tag}
                    </div>
                    {subLabel && <div className={OPTION_SUBLABEL_CLASS}>{subLabel}</div>}
                </div>
            </div>
            {rightContent && rightContentWidth && (
                <div className={OPTION_LABEL_RIGHT_CLASS}>{rightContent}</div>
            )}
        </>
    );

    const styleProps = {
        "--bb-popoverMenu-optionLabelRight-width": rightContentWidth
            ? // Add extra 4px to width to account for right padding
              rightContentWidth + getSizePx(SpacingTokens.SCALE_050) + "px"
            : 0,
    } as CSSProperties;

    // If the option is a link, then it should not include an <input> element, which means
    // it should also not include a <label>.
    const labelWrapper =
        href || type === MenuOptionType.INFO ? (
            <div
                ref={labelRef}
                className={"bb-popover-menu__option-label"}
                tabIndex={type === MenuOptionType.INFO && tooltip ? 0 : undefined}
                style={type === MenuOptionType.INFO ? styleProps : undefined}
                {...everIdProp(everId)}
            >
                {labelWrapperContent}
            </div>
        ) : (
            <label
                ref={labelRef}
                className={"bb-popover-menu__option-label"}
                htmlFor={inputId}
                style={styleProps}
                {...everIdProp(everId)}
            >
                {labelWrapperContent}
            </label>
        );

    return (
        <Item
            className={optionClasses}
            color={color}
            ellipsify={ellipsify}
            tooltip={tooltip}
            href={href}
            disableHref={disabled}
            link={link}
            everHashText={typeof label === "string" ? label : undefined}
        >
            {!href && type !== MenuOptionType.INFO && (
                <OptionInput
                    ref={inputRef}
                    inputId={inputId}
                    disabled={disabled}
                    onClick={onClick}
                    type={type}
                    selected={selected}
                    tabFocusable={tabFocusable}
                />
            )}
            {labelWrapper}
        </Item>
    );
};
