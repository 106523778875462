import clsx from "clsx";
import { FormSubmitButtonProps } from "components/Form";
import {
    DROPDOWN_BUTTON_CLASS,
    DROPDOWN_MENU_CLASS,
} from "components/Menu/DropdownMenu/DropdownMenu";
import { TEXT_INPUT_CLASS, TEXT_INPUT_WRAPPER_CLASS } from "components/TextInput/TextField";
import { Expandable } from "components/util/Expandable";
import { Memo } from "hooks/useBranded";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useReturnFocus } from "hooks/useReturnFocus";
import { useSetEverId } from "hooks/useSetEverId";
import { Dialog } from "primereact/dialog";

import "primereact/resources/primereact.min.css";
import React, {
    cloneElement,
    ReactElement,
    ReactNode,
    useEffect,
    useId,
    useMemo,
    useRef,
    useState,
} from "react";
import * as ButtonTokens from "tokens/typescript/ButtonTokens";
import * as ZIndexTokens from "tokens/typescript/zIndexTokens";
import {
    ButtonColor,
    ButtonProps,
    ButtonSize,
    ButtonWidth,
    generateButton,
    IconButton,
    TextButton,
    TextButtonProps,
} from "../Button";

import { X } from "../Icon";
import {
    BaseDialogProps,
    DialogFC,
    DialogHeader,
    DialogSize,
    useDialog,
    UseDialogProps,
    UseDialogResult,
    useToggleVisibilityWrapper,
} from "./Dialog";
import "./Dialog.scss";

export interface ModalProps extends BaseDialogProps {
    /**
     * An optional parameter that, when true, automatically focuses on the first focusable element
     * in the dialog when the dialog is shown.
     * Defaults to false.
     */
    autoFocus?: boolean;
    /**
     * An optional base z-index to assign to the dialog. Default {@link DIALOG_Z_INDEX_BASE}.
     */
    baseZIndex?: number;
    /**
     * An optional parameter that, when false, disallows the manual closing of the dialog.
     * Defaults to true.
     */
    closable?: boolean;
    /**
     * An optional parameter that, when false, disallows the manual closing of the dialog via
     * clicking outside the dialog.
     * Defaults to true.
     */
    dismissable?: boolean;
    /**
     * Optional content to display in the expandable section at the bottom of the dialog.
     */
    expandable?: ReactNode;
    /**
     * An optional boolean that, when true, sets the submit button to be loading, and disables
     * the closing or dismissing of the dialog.
     * Defaults to false.
     */
    loading?: boolean;
    /**
     * An optional callback that is called when the cancel button (i.e. the
     * secondaryButton) is clicked.
     */
    onCancel?: () => void;
    /**
     * An optional callback that is called when the complete button (i.e. the
     * primaryButton) is clicked.
     */
    onComplete?: () => void;
    /**
     * An optional parameter that specifies the primary button (i.e. the button displayed in the
     * bottom right) of the dialog.
     *
     * If no option is provided, a {@link ButtonColor.PRIMARY} {@link Button} with the text 'Ok'
     * will be used.
     *
     * If a raw {@link Button} is provided, that button will be used.
     *
     * If a string is provided, a {@link Button} with that string as its text will be used.
     *
     * If {@code null} is passed explicitly, the primary button will be omitted.
     */
    primaryButton?: ReactElement<ButtonProps> | ReactElement<FormSubmitButtonProps> | null | string;
    /**
     * An optional parameter that specifies the secondary button (i.e. the button displayed in the
     * bottom right, to the left of the primary button) of the dialog.
     *
     * If no option is provided, a {@link ButtonColor.SECONDARY} {@link Button} with the text
     * 'Cancel' will be used.
     *
     * If a raw {@link Button} is provided, that button will be used.
     *
     * If a string is provided, a {@link Button} with that string as its text will be used.
     *
     * If {@code null} is passed explicitly, the secondary button will be omitted.
     */
    secondaryButton?: ReactElement<ButtonProps> | null | string;
    /**
     * An optional tertiary button to display in the bottom left of the dialog.
     * If {@link expandable} is true, then this prop will be ignored and an
     * "Expand"/"Collapse" button will be displayed as the tertiary button.
     */
    tertiaryButton?: ReactElement<TextButtonProps>;
}

function generateModalButton<T extends ButtonProps | FormSubmitButtonProps>(
    button: Exclude<ModalProps["primaryButton"] | ModalProps["secondaryButton"], undefined>,
    extraProps: Partial<T>,
): ReactElement<T> | null {
    let props: Partial<ButtonProps> = { width: ButtonWidth.FLEXIBLE };
    if (button === null) {
        return null;
    } else if (typeof button === "string") {
        props = {
            ...props,
            minWidth: ButtonTokens.WIDTH_FIXED_LARGE,
            ...extraProps,
        };
    } else {
        props = {
            ...props,
            minWidth:
                button.props.size === ButtonSize.SMALL
                    ? ButtonTokens.WIDTH_FIXED_SMALL
                    : ButtonTokens.WIDTH_FIXED_LARGE,
            ...extraProps,
            ...button.props,
        };
    }
    return generateButton(button, props) as ReactElement<T>;
}

/**
 * If the previous focus element was a dropdown input, return focus to the dropdown button
 * instead of the input. Returning focus to the input causes the dropdown to open, which we
 * don't want, and can also lead to buggy behavior due to interactions with the dropdown's
 * useDetectClickOrFocusOutside handler.
 */
const GET_DROPDOWN_BUTTON = ((previousFocusElement: HTMLElement | null) => {
    if (previousFocusElement?.matches(`.${DROPDOWN_MENU_CLASS} .${TEXT_INPUT_CLASS}`)) {
        const inputWrapper = previousFocusElement.closest(`.${TEXT_INPUT_WRAPPER_CLASS}`);
        const dropdownButton = inputWrapper?.querySelector("." + DROPDOWN_BUTTON_CLASS);
        if (dropdownButton) {
            return dropdownButton as HTMLElement;
        }
    }
    return null;
}) as Memo<(previousFocusElement: HTMLElement | null) => HTMLElement | null>;

export interface UseModalProps extends UseDialogProps {
    /**
     * An optional callback that is called when the cancel button (i.e. the
     * secondaryButton) is clicked.
     *
     * Should return true if the dialog should be closed, and false if it should remain open.
     *
     * This function will be wrapped in another function, which toggles the visible state based
     * on the return value of the inner provided function.
     *
     * If no function is provided, the wrapping function will just set the visible state to false.
     */
    onCancel?: () => boolean;
    /**
     * An optional callback that is called when the complete button (i.e. the
     * primaryButton) is clicked.
     *
     * Should return true if the dialog should be closed, and false if it should remain open.
     *
     * This function will be wrapped in another function, which toggles the visible state based
     * on the return value of the inner provided function.
     *
     * If no function is provided, the wrapping function will just set the visible state to false.
     */
    onComplete?: () => boolean;
}

export interface UseModalResult extends Pick<UseDialogResult, "visible" | "setVisible"> {
    modalProps: UseDialogResult["dialogProps"] & {
        onCancel: () => void;
        onComplete: () => void;
    };
}

function useModal({
    onCancel: outerOnCancel,
    onComplete: outerOnComplete,
    ...useDialogProps
}: UseModalProps = {}): UseModalResult {
    const { visible, setVisible, dialogProps } = useDialog(useDialogProps);

    const onCancel = useToggleVisibilityWrapper(false, setVisible, outerOnCancel);
    const onComplete = useToggleVisibilityWrapper(false, setVisible, outerOnComplete);

    return useMemo(
        () => ({
            visible,
            setVisible,
            modalProps: {
                ...dialogProps,
                onCancel,
                onComplete,
            },
        }),
        [dialogProps, onCancel, onComplete, setVisible, visible],
    );
}

/**
 * A modal dialog component
 */
export const Modal: DialogFC<ModalProps, typeof useModal> = ({
    children,
    visible,
    id,
    everId,
    size,
    onShow,
    onHide,
    onCancel,
    onComplete,
    className,
    title,
    dismissable = true,
    primaryButton = "Ok",
    secondaryButton = "Cancel",
    tertiaryButton,
    baseZIndex = ZIndexTokens.DIALOG_BASE,
    expandable,
    autoFocus = true,
    closable = true,
    loading,
}) => {
    const generatedId = useId();
    id = id || generatedId;
    const [expanded, setExpanded] = useLocalStorage<boolean>(`${id}.expanded`, false);
    const [maskClassName, setMaskClassName] = useState("");
    const expandableId = useId();

    onHide = onHide || (() => {});
    const pixelWidth = `${size}px`;
    const headerEl = <DialogHeader width={size} title={title} />;

    let expandableSection = null;
    if (expandable) {
        tertiaryButton = (
            <TextButton
                onClick={() => setExpanded(!expanded)}
                className={"bb-dialog__tertiary-button"}
                aria-expanded={expanded}
                aria-controls={expandableId}
            >
                {expanded ? "Collapse" : "Expand"}
            </TextButton>
        );
        expandableSection = (
            <div className={"bb-dialog__expandable__container"} style={{ width: pixelWidth }}>
                <Expandable
                    id={expandableId}
                    expanded={expanded}
                    className={"bb-dialog__expandable"}
                >
                    <div className={"bb-dialog__expandable__content"}>{expandable}</div>
                </Expandable>
            </div>
        );
    } else if (tertiaryButton) {
        tertiaryButton = cloneElement(tertiaryButton, {
            ...tertiaryButton.props,
            className: clsx(tertiaryButton.props.className, "bb-dialog__tertiary-button"),
            size: ButtonSize.LARGE,
        });
    }

    let footerEl: React.ReactNode = (
        <div>
            <div className={clsx("h-spaced-12", "bb-dialog__footer")}>
                {tertiaryButton}
                {secondaryButton !== null
                    && generateModalButton(secondaryButton, {
                        onClick: onCancel,
                        color: ButtonColor.SECONDARY,
                        disabled: loading,
                    })}
                {primaryButton !== null
                    && generateModalButton(primaryButton, {
                        autoFocus,
                        onClick: onComplete,
                        loading,
                    })}
            </div>
            {expandableSection}
        </div>
    );

    if (primaryButton === null && secondaryButton === null && !tertiaryButton) {
        footerEl = null;
    }

    useReturnFocus(visible, GET_DROPDOWN_BUTTON);

    useEffect(() => {
        if (visible) {
            const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = `${scrollWidth}px`;
        }
        return () => {
            document.body.style.overflow = "unset";
            document.body.style.paddingRight = "0px";
        };
    }, [visible]);

    const dialogRef = useRef<Dialog>(null);
    useSetEverId([dialogRef.current?.getElement() || null, everId]);

    return (
        <Dialog
            ref={dialogRef}
            className={clsx("bb-component-content", "bb-dialog", className, {
                ["bb-dialog--fullscreen"]: size === DialogSize.FULLSCREEN,
            })}
            contentClassName={clsx("bb-dialog__content", {
                ["bb-dialog__content--no-footer"]: footerEl === null,
            })}
            maskClassName={clsx("bb-dialog__mask", "modal", maskClassName)}
            style={{ width: pixelWidth }}
            // The current base z-index value (900) is purposefully a bit smaller than the default
            // dojo dialog z-index (950). This is because backend error dialogs currently use
            // dojo dialogs, and we want those to appear over normal platform content dialogs.
            baseZIndex={baseZIndex}
            breakpoints={{ [pixelWidth]: "90vw" }}
            visible={visible}
            onShow={onShow}
            onHide={loading ? () => {} : onHide}
            draggable={false}
            closable={closable}
            dismissableMask={false}
            onMaskClick={(e) => {
                // only close if click started on the mask
                if (dismissable && e.target === e.currentTarget) {
                    onCancel && onCancel();
                }
            }}
            header={headerEl}
            footer={footerEl}
            focusOnShow={true}
            icons={
                <IconButton
                    aria-label={"Close dialog"}
                    onClick={onHide}
                    className={"bb-dialog__close"}
                >
                    <X />
                </IconButton>
            }
            resizable={false}
            transitionOptions={{
                timeout: { enter: 240, exit: 240 },
                onEnter: () => setMaskClassName("p-overlay-enter"),
                onEntering: () => setMaskClassName("p-overlay-enter-active"),
                onEntered: () => setMaskClassName("p-overlay-enter-done"),
                onExit: () => setMaskClassName("p-overlay-exit"),
                onExiting: () => setMaskClassName("p-overlay-exit-active"),
                onExited: () => setMaskClassName("p-overlay-exit-done"),
            }}
        >
            {children}
        </Dialog>
    );
};
Modal.use = useModal;

type OmittedAcknowledgmentCallbacks = "onCancel";

export type AcknowledgmentModalProps = Omit<
    ModalProps,
    OmittedAcknowledgmentCallbacks | "secondaryButton"
>;

export type UseAcknowledgmentProps = Omit<UseModalProps, OmittedAcknowledgmentCallbacks>;

export interface UseAcknowledgmentResult extends Pick<UseModalResult, "visible" | "setVisible"> {
    acknowledgmentProps: Omit<UseModalResult["modalProps"], OmittedAcknowledgmentCallbacks>;
}

function useAcknowledgment(props: UseAcknowledgmentProps = {}): UseAcknowledgmentResult {
    const useModalResult = useModal(props);
    return useMemo(() => {
        const { visible, setVisible, modalProps } = useModalResult;
        const { onCancel: _, ...acknowledgmentProps } = modalProps;
        return {
            visible,
            setVisible,
            acknowledgmentProps,
        };
    }, [useModalResult]);
}

export const Acknowledgment: DialogFC<AcknowledgmentModalProps, typeof useAcknowledgment> = ({
    ...props
}) => {
    return <Modal {...props} secondaryButton={null} />;
};
Acknowledgment.use = useAcknowledgment;

export type ConfirmationModalProps = ModalProps;

export type UseConfirmationProps = UseModalProps;

export interface UseConfirmationResult extends Pick<UseModalResult, "visible" | "setVisible"> {
    confirmationProps: UseModalResult["modalProps"];
}

function useConfirmation(props: UseConfirmationProps = {}): UseConfirmationResult {
    const useModalResult = useModal(props);
    return useMemo(() => {
        const { modalProps: confirmationProps, ...result } = useModalResult;
        return {
            ...result,
            confirmationProps,
        };
    }, [useModalResult]);
}

export const Confirmation: DialogFC<ConfirmationModalProps, typeof useConfirmation> = ({
    ...props
}) => {
    return <Modal {...props} />;
};
Confirmation.use = useConfirmation;

type OmittedPassiveCallbacks = "onComplete" | "onCancel";

export type PassiveModalProps = Omit<
    ModalProps,
    OmittedPassiveCallbacks | "primaryButton" | "secondaryButton" | "dismissable"
>;

export type UsePassiveProps = Omit<UseModalProps, OmittedPassiveCallbacks>;

export interface UsePassiveResult extends Pick<UseModalResult, "visible" | "setVisible"> {
    passiveProps: Omit<UseModalResult["modalProps"], OmittedPassiveCallbacks>;
}

function usePassive(props: UsePassiveProps = {}): UsePassiveResult {
    const useModalResult = useModal(props);
    return useMemo(() => {
        const { visible, setVisible, modalProps } = useModalResult;
        const { onCancel: _, onComplete: __, ...passiveProps } = modalProps;
        return {
            visible,
            setVisible,
            passiveProps,
        };
    }, [useModalResult]);
}

export const Passive: DialogFC<PassiveModalProps, typeof usePassive> = ({ ...props }) => {
    return <Modal {...props} secondaryButton={null} primaryButton={null} dismissable={true} />;
};
Passive.use = usePassive;

export type TransactionalModalProps = Omit<ModalProps, "autoFocus">;

export type UseTransactionalProps = UseModalProps;

export interface UseTransactionalResult extends Pick<UseModalResult, "visible" | "setVisible"> {
    transactionalProps: UseModalResult["modalProps"];
}

function useTransactional(props: UseTransactionalProps = {}): UseTransactionalResult {
    const useModalResult = useModal(props);
    return useMemo(() => {
        const { modalProps: transactionalProps, ...result } = useModalResult;
        return {
            ...result,
            transactionalProps,
        };
    }, [useModalResult]);
}

export const Transactional: DialogFC<TransactionalModalProps, typeof useTransactional> = ({
    ...props
}) => {
    return <Modal {...props} autoFocus={false} />;
};
Transactional.use = useTransactional;
