import clsx from "clsx";
import React, { forwardRef } from "react";
import { ReactNode } from "react";
import { FFC } from "util/type";
import "./Supplement.scss";

export interface SupplementProps {
    children: ReactNode;
    className?: string;
}

export const Supplement: FFC<HTMLDivElement, SupplementProps> = forwardRef(
    ({ children, className }, ref) => {
        return (
            <div className={clsx("bb-popover-menu__supplement", className)} ref={ref}>
                {children}
            </div>
        );
    },
);
Supplement.displayName = "Supplement";
