import clsx from "clsx";
import { ButtonColor, ButtonProps, ButtonSize, ButtonWidth, IconButton } from "components/Button";
import * as Icon from "components/Icon";
import * as CommonIcon from "components/Icon/CommonIcon";
import { IconProps } from "components/Icon/IconProps";
import { H2, HeadingMargin, Link } from "components/Text";
import { Tooltip } from "components/Tooltip";
import { everIdProp } from "EverAttribute/EverId";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, { cloneElement, FC, ReactElement, ReactNode, useId, useRef } from "react";
import { EverIdProp } from "util/type";
import "./Banner.scss";

interface BaseBannerProps extends EverIdProp {
    /**
     * An optional class name to apply to the element.
     */
    className?: string;
}

export interface TextBannerProps extends BaseBannerProps {
    /**
     * The text content of the banner.
     */
    children: ReactNode;
    /**
     * The function to be called when the close button is clicked. The close button will be
     * displayed if {@code onClose} is provided.
     */
    onClose?: () => void;
    /**
     * The url of the learn more link. If provided, a learn more link will be displayed after the
     * text content of the banner.
     */
    learnMoreUrl?: string;
    /**
     * If true, displays banner with a red background instead of yellow. Default false.
     */
    urgent?: boolean;
}

/**
 * An edge-to-edge banner for notifying users of lightweight changes or other announcements
 * (release info, support being down, etc).
 */
export const TextBanner: FC<TextBannerProps> = ({
    everId,
    className,
    children,
    onClose,
    learnMoreUrl,
    urgent = false,
}) => {
    const bannerRef = useRef<HTMLDivElement>(null);
    const messageId = useId();
    const [messageRef, messageEntry] = useResizeObserver<HTMLElement>();
    // We don't truncate the message when there is a learn more link because tab focusing it
    // can cause the contents to shift.
    const tooltipNeeded =
        !learnMoreUrl
        && messageEntry.target
        && messageEntry.target.scrollHeight > messageEntry.target.clientHeight;
    const bannerClasses = clsx("bb-text-banner", className, { "bb-text-banner--urgent": urgent });
    const messageClasses = clsx("bb-text-banner__message", {
        "bb-text-banner__message--ellipsed": !learnMoreUrl,
    });
    return (
        <>
            <div
                ref={bannerRef}
                className={bannerClasses}
                role={"alert"}
                aria-describedby={messageId}
                tabIndex={0}
                {...everIdProp(everId)}
            >
                <div id={messageId} ref={messageRef} className={messageClasses}>
                    {children}
                    {learnMoreUrl && (
                        <Link
                            className={"bb-text-banner__learn-more"}
                            href={learnMoreUrl}
                            newTab={true}
                        >
                            Learn more
                        </Link>
                    )}
                </div>
                {onClose && (
                    <div className={"bb-text-banner__close-button-container"}>
                        <IconButton aria-label={"Close banner"} onClick={onClose}>
                            <Icon.X />
                        </IconButton>
                    </div>
                )}
            </div>
            {tooltipNeeded && (
                <Tooltip
                    className={"bb-text-banner__tooltip"}
                    target={bannerRef}
                    showEvents={["focusin", "mouseenter"]}
                    hideEvents={["focusout", "mouseleave"]}
                    aria-hidden={true}
                >
                    {children}
                </Tooltip>
            )}
        </>
    );
};

export interface StatusBannerProps extends BaseBannerProps {
    /**
     * The text content of the banner.
     */
    children: ReactNode;
    /**
     * The url of the learn more link. If provided, a learn more link will be displayed after the
     * text content of the banner.
     */
    learnMoreUrl?: string;
    /**
     * Whether a loading icon should be displayed before the message. Default false.
     */
    showLoadingIcon?: boolean;
}

/**
 * An edge-to-edge banner used to notify users of any in-progress actions. Often supplements
 * tasks started in toasts.
 */
export const StatusBanner: FC<StatusBannerProps> = ({
    everId,
    className,
    children,
    learnMoreUrl,
    showLoadingIcon = false,
}) => {
    const messageId = useId();
    return (
        <div
            className={clsx("bb-status-banner", className)}
            role={"status"}
            aria-describedby={messageId}
            tabIndex={0}
            {...everIdProp(everId)}
        >
            {showLoadingIcon && (
                <CommonIcon.Loading
                    className={"bb-status-banner__loading-icon"}
                    size={20}
                    aria-hidden={true}
                />
            )}
            <div id={messageId}>
                {children}
                {learnMoreUrl && (
                    <Link
                        className={"bb-status-banner__learn-more"}
                        href={learnMoreUrl}
                        newTab={true}
                    >
                        Learn more
                    </Link>
                )}
            </div>
        </div>
    );
};

interface BaseInlineBannerProps extends BaseBannerProps {
    /**
     * The text content of the banner, which will be placed after the given icon.
     */
    children: ReactNode;
    /**
     * The icon on the left-hand side of the banner.
     */
    icon: ReactElement<IconProps>;
    /**
     * The url of the learn more link. If provided, a learn more link will be displayed after the
     * text content of the banner.
     */
    learnMoreUrl?: string;
    /**
     * The function to be called when the close button is clicked.
     */
    onClose?: () => void;
}

function BaseInlineBanner({
    everId,
    className,
    children,
    icon,
    learnMoreUrl,
    onClose,
}: BaseInlineBannerProps) {
    const messageId = useId();
    icon = cloneElement(icon, {
        className: "bb-inline-banner__icon",
        size: 20,
        "aria-hidden": true,
    });
    return (
        <div
            className={clsx("bb-inline-banner", className, {
                "bb-inline-banner--dismissable": onClose,
            })}
            role={"status"}
            aria-describedby={messageId}
            tabIndex={0}
            {...everIdProp(everId)}
        >
            <div className={"bb-inline-banner__left-content"}>
                {icon}
                <div id={messageId}>
                    {children}
                    {learnMoreUrl && (
                        <Link
                            className={"bb-inline-banner__learn-more"}
                            href={learnMoreUrl}
                            newTab={true}
                        >
                            Learn more
                        </Link>
                    )}
                </div>
            </div>
            {onClose && (
                <IconButton
                    className={"bb-inline-banner__close-button"}
                    aria-label={"Close banner"}
                    onClick={onClose}
                >
                    <Icon.X size={20} />
                </IconButton>
            )}
        </div>
    );
}

export type InlineBannerProps = Omit<BaseInlineBannerProps, "onClose">;

/**
 * A banner for lightweight warnings or informational messages that apply to page or section.
 * This banner cannot be dismissed. It is the only banner that may appear at the bottom of a page
 * or section.
 */
export function InlineBanner({ children, ...props }: BaseInlineBannerProps) {
    return <BaseInlineBanner {...props}>{children}</BaseInlineBanner>;
}

export type TipBannerProps = BaseInlineBannerProps;

/**
 * A banner for tips which provides supplemental information that is non-critical to
 * the task at hand.
 */
export function TipBanner({ children, className, ...props }: TipBannerProps) {
    return (
        <BaseInlineBanner className={clsx(className, "bb-tip-banner")} {...props}>
            {children}
        </BaseInlineBanner>
    );
}

const ACTION_BANNER_MIN_HEIGHT = 56;

export interface ActionBannerProps extends BaseBannerProps {
    /**
     * The text content of the banner which will be placed after the given icon.
     */
    children: ReactNode;
    /**
     * The icon on the left-hand side of the banner.
     */
    icon: ReactElement<IconProps>;
    /**
     * The primary button in the right-most position of the banner.
     */
    primaryButton: ReactElement<ButtonProps>;
    /**
     * The secondary button to the left of the primary button. Should only be given if
     * {@code primaryButton} is.
     */
    secondaryButton?: ReactElement<ButtonProps>;
    /**
     * The url of the learn more link. If provided, a learn more link will be displayed after the
     * text content of the banner.
     */
    learnMoreUrl?: string;
}

/**
 * A banner for warnings, errors, or informational messages that apply to a page or section.
 * Users must complete an action to dismiss the banner.
 */
export const ActionBanner: FC<ActionBannerProps> = ({
    everId,
    className,
    children,
    icon,
    primaryButton,
    secondaryButton,
    learnMoreUrl,
}) => {
    const [bannerRef, bannerEntry] = useResizeObserver<HTMLElement>();
    const isMultiline =
        bannerEntry.target && bannerEntry.target.clientHeight > ACTION_BANNER_MIN_HEIGHT;
    const contentClasses = clsx("bb-action-banner__content", {
        "bb-action-banner__content--multiline": isMultiline,
    });
    const messageId = useId();
    icon = cloneElement(icon, {
        size: 20,
        "aria-hidden": true,
    });
    primaryButton = cloneElement(primaryButton, {
        color: ButtonColor.PRIMARY,
        size: ButtonSize.SMALL,
        width: ButtonWidth.FLEXIBLE,
    });
    secondaryButton &&= cloneElement(secondaryButton, {
        color: ButtonColor.SECONDARY,
        size: ButtonSize.SMALL,
        width: ButtonWidth.FLEXIBLE,
    });
    return (
        <div
            ref={bannerRef}
            className={clsx("bb-action-banner", className)}
            role={"alert"}
            aria-describedby={messageId}
            tabIndex={0}
            {...everIdProp(everId)}
        >
            <div
                // We need to create this wrapper div to apply overflow: hidden, since applying it
                // on the parent element would suppress display of the focus-visible :after
                // pseudo-element.
                className={"bb-action-banner__content-wrapper"}
            >
                <div className={contentClasses}>
                    {icon}
                    <div id={messageId} className={"bb-action-banner__message"}>
                        {children}
                        {learnMoreUrl && (
                            <Link
                                className={"bb-action-banner__learn-more"}
                                href={learnMoreUrl}
                                newTab={true}
                            >
                                Learn more
                            </Link>
                        )}
                    </div>
                </div>
                <div className={"bb-action-banner__button-container"}>
                    {secondaryButton}
                    {primaryButton}
                </div>
            </div>
        </div>
    );
};

export interface ModeBannerProps extends BaseBannerProps {
    /**
     * The mode title of the banner.
     */
    modeTitle: string;
    /**
     * The description which appears under the mode title and provides additional detail.
     */
    children?: ReactNode;
    /**
     * The first (left) button in the banner.
     */
    firstButton?: ReactElement<ButtonProps>;
    /**
     * The second (right) button in the banner. Should only be given if {@code firstButton} is.
     */
    secondButton?: ReactElement<ButtonProps>;
    /**
     * The function to be called when the close button is clicked.
     */
    onClose: () => void;
}

/**
 * An edge-to-edge banner that is used when a different mode is enabled. A different mode is
 * enabled when the available actions on a page or section are temporarily changed as a result
 * of users clicking a button.
 */
export const ModeBanner: FC<ModeBannerProps> = ({
    everId,
    className,
    modeTitle,
    children,
    firstButton,
    secondButton,
    onClose,
}) => {
    const bannerId = useId();
    const titleId = "bb-mode-banner-" + bannerId + "__mode-title";
    const descId = "bb-mode-banner-" + bannerId + "__description";
    const containerId = "bb-mode-banner-" + bannerId + "__container";
    const [resizeRef, resizeEntry] = useResizeObserver<HTMLDivElement>();
    const tooltipTargetRef = useRef<HTMLDivElement>(null);
    const tooltipNeeded =
        resizeEntry.target && resizeEntry.target.scrollWidth > resizeEntry.target.clientWidth;
    const descRef = useCombinedRef(resizeRef, tooltipTargetRef);

    const buttonProps = {
        color: ButtonColor.SECONDARY,
        size: ButtonSize.SMALL,
        width: ButtonWidth.FLEXIBLE,
    };
    firstButton &&= cloneElement(firstButton, buttonProps);
    secondButton &&= cloneElement(secondButton, buttonProps);

    return (
        <div
            className={clsx("bb-mode-banner", className)}
            role={"alert"}
            aria-live={"polite"}
            aria-labelledby={titleId}
            aria-describedby={containerId}
            tabIndex={0}
            {...everIdProp(everId)}
        >
            <div id={containerId} className={"bb-mode-banner__title-desc-container"}>
                <H2.Tiny id={titleId} marginType={HeadingMargin.NONE}>
                    {modeTitle}
                </H2.Tiny>
                {children && (
                    <div id={descId} ref={descRef} className={"bb-mode-banner__description"}>
                        {children}
                    </div>
                )}
                {tooltipNeeded && (
                    <Tooltip
                        className={"bb-mode-banner__tooltip"}
                        target={tooltipTargetRef}
                        showEvents={["focusin", "mouseenter"]}
                        hideEvents={["focusout", "mouseleave"]}
                        aria-hidden={true}
                    >
                        {children}
                    </Tooltip>
                )}
            </div>
            <div className={"bb-mode-banner__button-container"}>
                {firstButton}
                {secondButton}
                {onClose && (
                    <IconButton aria-label={"Close banner"} onClick={onClose}>
                        <Icon.X />
                    </IconButton>
                )}
            </div>
        </div>
    );
};
