import clsx from "clsx";
import { Item, MenuItemProps } from "components/Menu/Item/Item";
import { labelTooltipContent, onItemInputKeyUp } from "components/Menu/Item/ItemUtil";
import {
    TOGGLE_MAIN_LABEL_CLASS,
    TOGGLE_SUBLABEL_CLASS,
    ToggleSwitch as ToggleSwitchComponent,
    ToggleSwitchProps,
} from "components/ToggleSwitch/ToggleSwitch";
import { Tooltip } from "components/Tooltip";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, { forwardRef, useRef } from "react";
import { FFC } from "util/type";
import "./ToggleSwitch.scss";

export type PopoverMenuToggleSwitchProps = Omit<ToggleSwitchProps, "className"> &
    Pick<MenuItemProps, "className" | "ellipsify" | "color">;

/**
 * A PopoverMenu version of a Toggle. Handles the necessary styling for placing a Toggle within
 * a PopoverMenu as a menu item.
 */
export const ToggleSwitch: FFC<HTMLInputElement, PopoverMenuToggleSwitchProps> = forwardRef(
    ({ className, color, ellipsify = false, tooltip, ...toggleProps }, ref) => {
        className = clsx("bb-popover-menu__toggle", className);
        const internalRef = useRef<HTMLInputElement>(null);
        const inputRef = useCombinedRef(ref, internalRef);

        const [itemResizeRef, itemResizeEntry] = useResizeObserver<HTMLDivElement>();
        const labelContent = labelTooltipContent(toggleProps);
        const itemWidth = itemResizeEntry.contentRect.width;
        if (!tooltip && itemWidth && labelContent && ellipsify) {
            const mainLabelElement = itemResizeEntry.target?.querySelector(
                "." + TOGGLE_MAIN_LABEL_CLASS,
            );
            const subLabelElement = itemResizeEntry.target?.querySelector(
                "." + TOGGLE_SUBLABEL_CLASS,
            );
            if (
                (!!mainLabelElement && mainLabelElement.scrollWidth > mainLabelElement.clientWidth)
                || (!!subLabelElement && subLabelElement.scrollWidth > subLabelElement.clientWidth)
            ) {
                tooltip = <Tooltip aria-hidden={true}>{labelContent}</Tooltip>;
            }
        }

        return (
            <Item
                ref={itemResizeRef}
                className={className}
                color={color}
                ellipsify={ellipsify}
                tooltip={tooltip}
                everHashText={typeof toggleProps.label === "string" ? toggleProps.label : undefined}
            >
                <ToggleSwitchComponent
                    {...toggleProps}
                    ref={inputRef}
                    onKeyUp={onItemInputKeyUp(internalRef, toggleProps.onKeyUp)}
                    isMenuToggleSwitch={true}
                />
            </Item>
        );
    },
);
ToggleSwitch.displayName = "ToggleSwitch";
