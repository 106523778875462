import clsx from "clsx";
import { IconButton } from "components/Button";
import { CheckboxValue } from "components/Checkbox";
import * as Icon from "components/Icon";
import { MarkedText } from "components/MarkedText";
import {
    Checkbox,
    CreateOption,
    Load,
    MenuCreateOptionType,
    MenuOptionProps,
    MenuOptionType,
    Option,
    Section,
    Supplement,
} from "components/Menu/Item";
import { Scrollbar } from "components/Scrollbar";
import { Span } from "components/Text";
import { TextField, TextFieldInputType, TextFieldWidth } from "components/TextInput";
import { TooltipPlacement, useEllipsisTooltip } from "components/Tooltip";
import { useArrowNav } from "hooks/useArrowNav";
import { useAsyncDebounce } from "hooks/useAsyncDebounce";
import { Memo, useBrandedCallback, useBrandedMemo } from "hooks/useBranded";
import { useCombinedRef } from "hooks/useCombinedRef";
import { useEventListener } from "hooks/useEventListener";
import React, {
    ChangeEvent,
    Dispatch,
    forwardRef,
    MouseEvent,
    ReactNode,
    Ref,
    SetStateAction,
    useCallback,
    useId,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import * as BorderTokens from "tokens/typescript/BorderTokens";
import * as ListBoxTokens from "tokens/typescript/ListBoxTokens";
import { getSizePx } from "util/css";
import { FlattenedSection, ListBoxOptionWithNesting } from "./ListBoxUtil";
import { useLatest } from "hooks/useLatest";

export enum ListBoxSize {
    SMALL = "small",
    LARGE = "large",
}

export type OptionId = string | number;

export interface ListBoxOption<T extends OptionId = OptionId>
    extends Pick<
        MenuOptionProps,
        "color" | "disabled" | "tooltip" | "rightContent" | "rightContentWidth"
    > {
    /**
     * The id of the option, which must be unique.
     */
    id: T;
    /**
     * The name of the option. This value will be used as the option label, unless {@link label}
     * is provided. If the listbox has multi-select, then it will also be used as the content
     * of the associated chip in the selection summary.
     */
    name: string;
    /**
     * Use this to display non-string elements in the label.
     *
     * By default, {@link name} is used for filtering. Thus, if there is content in {@link label}
     * that is not reflected in {@link name} but should be filterable, then use
     * {@link SharedListBoxProps#filterValue} and {@link SharedListBoxProps#setFilterValue} to
     * perform filtering manually. Be sure to also use {@link MarkedText} to ensure that
     * all filterable label content is properly highlighted.
     */
    label?: ReactNode;
    /**
     * A list of options to nest under this option. Note that the max nesting level is 4.
     * Any options nested more deeply than that will not be shown.
     */
    subOptions?: ListBoxOption<T>[];
}

export interface ListBoxSection<T extends OptionId = OptionId> {
    /**
     * The heading of the section.
     */
    heading?: string;
    /**
     * A list of options in the section.
     */
    options: ListBoxOption<T>[];
}

interface ListBoxOptionProps<T extends OptionId>
    extends Pick<BaseListBoxProps<T>, "isMulti" | "onOptionClick"> {
    option: ListBoxOptionWithNesting<T>;
    filterValue: string;
    selected: boolean;
    itemIndex: number;
    tabFocusable: boolean;
    disabled: boolean;
}

const ListBoxOptionInternal = <T extends OptionId>(
    {
        isMulti,
        onOptionClick,
        option,
        filterValue,
        selected,
        itemIndex,
        tabFocusable,
        disabled,
    }: ListBoxOptionProps<T>,
    ref: Ref<HTMLInputElement>,
) => {
    const internalInputRef = useRef<HTMLInputElement>(null);
    const inputRef = useCombinedRef(internalInputRef, ref);
    const onInputKeydown = useBrandedCallback((e: Event) => {
        if (!(e instanceof KeyboardEvent)) {
            return;
        }
        // Prevent left and right arrow keys from navigating to and selecting the
        // previous/next listbox option.
        if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
            e.preventDefault();
        }
    }, []);
    useEventListener(internalInputRef, "keydown", onInputKeydown);
    const sharedProps = {
        ...option,
        key: option.id,
        label: option.label || <MarkedText textToMark={filterValue}>{option.name}</MarkedText>,
        disabled: option.disabled || disabled,
        tabFocusable: !disabled && tabFocusable,
        ellipsify: true,
    };
    return isMulti ? (
        <Checkbox
            {...sharedProps}
            ref={inputRef}
            value={selected ? CheckboxValue.TRUE : CheckboxValue.FALSE}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onOptionClick(option.id, e.nativeEvent, itemIndex)
            }
        />
    ) : (
        <Option
            {...sharedProps}
            inputRef={inputRef}
            type={MenuOptionType.LISTBOX_OPTION}
            selected={selected}
            onClick={(e: MouseEvent<HTMLInputElement>) =>
                onOptionClick(option.id, e.nativeEvent, itemIndex)
            }
        />
    );
};

// Generic components don't play well with forwardRef, so we need to cast the result of forwardRef.
const ListBoxOption = forwardRef(ListBoxOptionInternal) as <T extends OptionId>(
    p: ListBoxOptionProps<T> & { ref?: Ref<HTMLInputElement> },
) => ReactNode;

export interface SharedListBoxProps<T extends OptionId> {
    /**
     * An optional class name to apply to the listbox outer wrapper.
     */
    className?: string;
    /**
     * The heading text to display above the listbox.
     */
    heading: string;
    /**
     * The sub-heading to display next to the heading. The sub-heading will be displayed in
     * gray text and wrapped in parentheses.
     */
    subHeading?: string;
    /**
     * The content to display on the right-hand side of the heading.
     */
    headingRightContent?: ReactNode;
    /**
     * The filter input placeholder to display when no options have been selected and no text
     * has been entered into the filter input.
     */
    placeholder: string;
    /**
     * The height variant of the listbox. Default {@link ListBoxSize.LARGE}.
     */
    height?: ListBoxSize;
    /**
     * A list of {@link ListBoxOption}s or {@link ListBoxSection}s to display in the listbox.
     * Items will be displayed in the provided order.
     */
    items: Memo<ListBoxOption<T>[] | ListBoxSection<T>[]>;
    /**
     * Whether the listbox should be disabled. Default false.
     */
    disabled?: boolean;
    /**
     * The value of the filter input above the listbox items.
     *
     * If {@link filterValue} and {@link setFilterValue} are both provided, then the listbox
     * will be not be filtered automatically. Automatic filtering only detects matches in
     * {@link ListBoxOption.name}, so manual filtering is useful when there is filterable
     * content in {@link ListBoxOption#label} that isn't in {@link ListBoxOption.name}
     */
    filterValue?: string;
    /**
     * The setter for the {@link filterValue} state variable.
     *
     * If {@link filterValue} and {@link setFilterValue} are both provided, then the listbox
     * will be not be filtered automatically. Automatic filtering only detects matches in
     * {@link ListBoxOption.name}, so manual filtering is useful when there is filterable
     * content in {@link ListBoxOption.label} that isn't in {@link ListBoxOption.name}
     */
    setFilterValue?: Memo<Dispatch<SetStateAction<string>> | ((value: string) => void)>;
    /**
     * If provided, then typing in the filter input will cause a "new/custom" option with the
     * filter text to appear at the top of the listbox. {@link onCreateOption} will be called
     * when the user selects the new option or hits the "Enter" key while the filter input
     * is focused.
     *
     * Note: Generally the newly created option should be automatically selected.
     */
    onCreateOption?: Memo<(newOption: string) => void>;
    /**
     * The type of the create option. Only applicable when {@link onCreateOption} is provided.
     * Default {@link MenuCreateOptionType.NEW};
     */
    createType?: MenuCreateOptionType;
    /**
     * If provided, a "Load more" option will be displayed at the bottom of the listbox.
     */
    onLoadMore?: () => void;
    /**
     * Whether the "Load more" option should be in the loading state. Only applicable when
     * {@link onLoadMore} is provided.
     */
    loadingMore?: boolean;
    /**
     * An optional element to place at the top of the menu, which stays displayed regardless
     * of scrolling. This will usually be a {@link Supplement}.
     */
    stickyHeader?: ReactNode;
}

export interface BaseListBoxProps<T extends OptionId>
    extends Omit<SharedListBoxProps<T>, "items" | "filterValue" | "setFilterValue">,
        Required<Pick<SharedListBoxProps<T>, "filterValue" | "setFilterValue">> {
    sections: Memo<FlattenedSection<T>[]>;
    isMulti: boolean;
    selected: T | Set<T> | null;
    onOptionClick: Memo<(option: T, event: Event, index: number) => void>;
    selectedInputDisplay: string;
    optionNames: Memo<Set<string>>;
}

export function BaseListBox<T extends OptionId>({
    className,
    heading,
    subHeading,
    headingRightContent,
    placeholder,
    height = ListBoxSize.LARGE,
    sections,
    disabled = false,
    filterValue,
    setFilterValue,
    onCreateOption,
    createType = MenuCreateOptionType.NEW,
    optionNames,
    onLoadMore,
    loadingMore,
    stickyHeader,
    isMulti,
    selected,
    onOptionClick,
    selectedInputDisplay,
}: BaseListBoxProps<T>) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const inputValueRef = useLatest(inputValue);
    const [inputFocused, setInputFocused] = useState<boolean>(false);
    const onFilterChange = useAsyncDebounce(setFilterValue, 200);

    const [focusInBox, setFocusInBox] = useState(false);
    const arrowNavContainerRef = useRef<HTMLDivElement>(null);
    useArrowNav(arrowNavContainerRef, {
        tabbableElementsOnly: false,
        excludeSelectors: [".bb-text-field__button"],
    });

    const headingId = useId();
    const { tooltipComponent, tooltipTargetProps } = useEllipsisTooltip({
        children: (
            <>
                {heading}
                {subHeading && ` (${subHeading})`}
            </>
        ),
        placement: [
            TooltipPlacement.BOTTOM,
            TooltipPlacement.BOTTOM_START,
            TooltipPlacement.BOTTOM_END,
        ],
        "aria-hidden": true,
        targetClassName: clsx("bb-list-box__heading", "h-spaced-4"),
    });

    const showCreateOption =
        !disabled
        && !!onCreateOption
        && !!filterValue
        && !optionNames.has(inputValueRef.current.trim().toLowerCase());
    const onCreateOptionInternal = useCallback(() => {
        if (showCreateOption) {
            onCreateOption(inputValueRef.current);
            inputRef.current?.focus();
            setInputValue("");
            setFilterValue("");
        }
    }, [inputValueRef, onCreateOption, setFilterValue, showCreateOption]);
    const createOption = useMemo(() => {
        return showCreateOption ? (
            <CreateOption
                itemName={inputValue}
                onClick={onCreateOptionInternal}
                createType={createType}
                ellipsify={true}
            />
        ) : undefined;
    }, [createType, inputValue, onCreateOptionInternal, showCreateOption]);

    const listBoxContents = useBrandedMemo(() => {
        const emptySelection = selected instanceof Set ? !selected.size : selected === null;
        let itemIndex = -1;
        return sections.map((section, i) => (
            <Section key={section.heading || "first section"} header={section.heading}>
                {
                    // Stick "create" option at the top of the first section if it's header-less.
                    // Otherwise, it will go in its own section at the top.
                    i === 0 && !section.heading && createOption
                }
                {section.options.map((option, j) => {
                    itemIndex++;
                    const isSelected =
                        selected instanceof Set ? selected.has(option.id) : selected === option.id;
                    const isFirstOption = i === 0 && j === 0;
                    const isLastOption =
                        i === sections.length - 1 && j === section.options.length - 1;
                    return (
                        <ListBoxOption
                            key={option.id}
                            option={option}
                            selected={isSelected}
                            itemIndex={itemIndex}
                            isMulti={isMulti}
                            disabled={disabled}
                            filterValue={filterValue}
                            onOptionClick={onOptionClick}
                            tabFocusable={
                                // Keyboard nav is handled by useArrowNav, so options are not
                                // tab focusable by default. The exception is when the listbox
                                // isn't focused. In this situation, we make selected options
                                // tabbable, or the first and last options if none are selected.
                                // This way, the first selected option (or first option, if none
                                // selected) is focused when tabbing in from above, and the last
                                // selected option (or last option, if none selected) is focused
                                // when tabbing in from above.
                                !focusInBox
                                && (isSelected
                                    || (emptySelection && (isFirstOption || isLastOption)))
                            }
                        />
                    );
                })}
            </Section>
        ));
    }, [
        selected,
        sections,
        createOption,
        isMulti,
        disabled,
        filterValue,
        onOptionClick,
        focusInBox,
    ]);

    // Adjust scrolling div height for top/bottom border as well as sticky header and footer.
    const defaultHeight =
        (height === ListBoxSize.SMALL
            ? getSizePx(ListBoxTokens.HEIGHT_SMALL)
            : getSizePx(ListBoxTokens.HEIGHT_LARGE))
        - 2 * getSizePx(BorderTokens.WIDTH_PRIMARY);
    const [heightPx, setHeightPx] = useState<number>(defaultHeight);
    const [stickyHeaderEl, setStickyHeaderEl] = useState<HTMLDivElement | null>(null);
    const [stickyFooterEl, setStickyFooterEl] = useState<HTMLDivElement | null>(null);
    useLayoutEffect(() => {
        const headerHeight: number = stickyHeaderEl?.offsetHeight || 0;
        const footerHeight: number = stickyFooterEl?.offsetHeight || 0;
        setHeightPx(defaultHeight - headerHeight - footerHeight);
    }, [defaultHeight, stickyFooterEl, stickyHeaderEl]);

    return (
        <div
            className={clsx(className, "bb-list-box", { "bb-list-box--disabled": disabled })}
            role={"listbox"}
            aria-multiselectable={isMulti}
            aria-labelledby={headingId}
        >
            <div className={"bb-list-box__heading-wrapper"}>
                <div id={headingId} role={"label"} {...tooltipTargetProps}>
                    <Span.Semibold>{heading}</Span.Semibold>
                    {subHeading && (
                        <Span className={"bb-text--color-secondary"}>({subHeading})</Span>
                    )}
                </div>
                {headingRightContent}
            </div>
            {tooltipComponent}
            <div ref={arrowNavContainerRef}>
                <TextField
                    ref={inputRef}
                    label={"Filter"}
                    hideLabel={true}
                    width={TextFieldWidth.FULL}
                    type={TextFieldInputType.SEARCH}
                    placeholder={
                        (!inputValue && inputFocused && selectedInputDisplay) || placeholder
                    }
                    value={inputValue || (!inputFocused ? selectedInputDisplay : "")}
                    disabled={disabled}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        onFilterChange(e.target.value.toLowerCase().trim());
                    }}
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                    onKeyUp={(e) => e.key === "Enter" && onCreateOptionInternal()}
                    rightButtons={
                        inputValue ? (
                            <IconButton
                                aria-label={"Clear filter"}
                                onClick={() => {
                                    setInputValue("");
                                    setFilterValue("");
                                }}
                            >
                                <Icon.X size={20} />
                            </IconButton>
                        ) : undefined
                    }
                />
                <div className={"bb-list-box__box"}>
                    {stickyHeader && (
                        <div className={"bb-popover-menu__sticky-header"} ref={setStickyHeaderEl}>
                            {stickyHeader}
                        </div>
                    )}
                    <Scrollbar
                        autoHeight={true}
                        autoHeightMin={heightPx + "px"}
                        autoHeightMax={heightPx + "px"}
                        hideTracksWhenNotNeeded={true}
                        thumbSize={60}
                    >
                        <div
                            className={"bb-list-box__items"}
                            onFocus={() => setFocusInBox(true)}
                            onBlur={() => setFocusInBox(false)}
                        >
                            {createOption && (!sections.length || sections[0].heading) && (
                                <Section key={"section_create-option"}>{createOption}</Section>
                            )}
                            {listBoxContents}
                            {!sections.length && !createOption && (
                                <Supplement>No results</Supplement>
                            )}
                        </div>
                    </Scrollbar>
                    {onLoadMore && !disabled && (
                        <div className={"bb-popover-menu__sticky-footer"} ref={setStickyFooterEl}>
                            <Load loading={loadingMore} onClick={onLoadMore} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
