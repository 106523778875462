import { BaseButtonProps, BaseSplitButtonProps } from "components/Button/BaseButton";
import clsx from "clsx";
import { ButtonSize } from "components/Button/Button/Button";
import * as Icon from "components/Icon";
import { getIconSize, ICON_SIZE } from "components/Icon/BaseIcon";
import { IconProps } from "components/Icon/IconProps";
import { everIdProp } from "EverAttribute/EverId";
import { useCombinedRef } from "hooks/useCombinedRef";
import React, { CSSProperties, FC, forwardRef, ReactElement, useRef } from "react";
import * as ColorTokens from "tokens/typescript/ColorTokens";
import * as IconButtonTokens from "tokens/typescript/IconButtonTokens";
import "../Button.scss";
import CSS from "csstype";

const LABELED_ICON_BUTTON_MIN_WIDTH = IconButtonTokens.MIN_WIDTH_LABELED_DEFAULT;

export interface IconButtonProps
    extends Omit<BaseButtonProps<ReactElement<IconProps>>, "aria-label" | "aria-labelledby"> {
    /**
     * A label to describe the button for accessibility tools. Will not affect the DOM. One of this
     * prop, aria-labelledby, or label should be specified for all buttons.
     */
    "aria-label"?: string;
    /**
     * The id or ids (space separated) of the element(s) that contain a label for the button.
     * Use this when the label is provided by another element, like a tooltip. One of this
     * prop, aria-label, or label should be specified for all buttons.
     */
    "aria-labelledby"?: string;
    /**
     * The label for the button. One of this prop, aria-label, or aria-labelledby should be
     * specified for all buttons.
     */
    label?: string;
    /**
     * The minimum width of the icon button, if it has a label.
     * Default {@link LABELED_ICON_BUTTON_MIN_WIDTH}.
     */
    labeledMinWidth?: CSS.Property.MinWidth;
}

export interface SplitIconButtonProps extends BaseSplitButtonProps<Omit<IconButtonProps, "label">> {
    /**
     * The label for the side button.
     */
    sideButtonLabel?: string;
    /**
     * The label for the main button. Either this property or mainButton-aria-label should be
     * provided for all split icon buttons.
     */
    mainButtonLabel?: string;
}

function getIconButtonSize(iconProps: IconProps): ButtonSize {
    const iconSize = getIconSize(iconProps.size);
    return typeof iconSize === "number" && iconSize <= ICON_SIZE.SMALL
        ? ButtonSize.SMALL
        : ButtonSize.LARGE;
}

function warnIfNoLabel(
    label: string | undefined,
    ariaLabel: string | undefined,
    ariaLabelledBy: string | undefined,
) {
    if (label || ariaLabel || ariaLabelledBy) {
        return;
    }
    console.warn("Icon buttons must have either a label, aria-label, or aria-labelledby");
}

/**
 * A button which contains just an icon. Like a text button, is meant to be placed inline with
 * content, and looks flush with the page until hovered.
 */
export const IconButton = forwardRef<HTMLButtonElement, Omit<IconButtonProps, "tooltip">>(
    (
        {
            children,
            className,
            onClick: onClickProp,
            onMouseDown,
            disabled,
            active,
            label,
            labeledMinWidth = LABELED_ICON_BUTTON_MIN_WIDTH,
            "aria-label": ariaLabel,
            "aria-labelledby": ariaLabelledBy,
            tabFocusable = true,
            style,
            everId,
            ...props
        },
        ref,
    ) => {
        warnIfNoLabel(label, ariaLabel, ariaLabelledBy);
        const internalButtonRef = useRef<HTMLButtonElement>(null);
        const finalRef = useCombinedRef(ref, internalButtonRef);
        const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            // Only trigger onClick if the click target is the button itself.
            if (internalButtonRef.current && e.target === internalButtonRef.current) {
                onClickProp?.(e);
                return;
            }

            // Don't let this click event bubble up because its target will often be the button's icon
            // which could be removed from the DOM in some cases (and thus be "outside" of the button)
            // by the time useDetectClickOrFocusOutside runs its callback.
            // This icon removal issue is likely to happen for IconButtons that change their icons
            // on click.
            e.stopPropagation();
            // Manually dispatch a click event to "replace" the one suppressed before. This click event
            // will have its target set to the button itself so that other components' outside click
            // detection will still be triggered correctly.
            internalButtonRef.current?.click();
        };
        children = React.cloneElement(children, {
            color: children.props.color || ColorTokens.ICON_BUTTON_PRIMARY,
            size: label ? ICON_SIZE.SMALL : children.props.size,
            "aria-hidden": true,
        });
        const buttonSize = getIconButtonSize(children.props);
        className = clsx("bb-icon-button", `bb-icon-button--${buttonSize}`, className, {
            "bb-icon-button--active": active,
            "bb-icon-button--labeled": !!label,
        });
        return (
            <button
                ref={finalRef}
                className={className}
                onClick={(e) => !disabled && onClick(e)}
                onMouseDown={(e) => !disabled && onMouseDown?.(e)}
                aria-disabled={disabled}
                type={"button"}
                aria-label={ariaLabel}
                aria-labelledby={ariaLabelledBy}
                tabIndex={!tabFocusable ? -1 : undefined}
                style={
                    {
                        "--bb-iconButton-labeled-minWidth": labeledMinWidth,
                        ...style,
                    } as CSSProperties
                }
                {...everIdProp(everId)}
                {...props}
            >
                {children}
                {label && <div className={"bb-icon-button__label"}>{label}</div>}
            </button>
        );
    },
);
IconButton.displayName = "IconButton";

/**
 * An icon button split up into two parts, a main button and a dropdown button. The main button
 * performs some primary action, while the dropdown button allows access to some secondary actions.
 */
export const SplitIconButton: FC<SplitIconButtonProps> = ({
    children,
    "sideButton-aria-label": sideButtonAriaLabel = "Expand",
    "sideButton-aria-describedby": sideButtonDescribedby,
    "sideButton-aria-expanded": sideButtonExpanded,
    "sideButton-aria-controls": sideButtonControls,
    "mainButton-aria-label": mainButtonAriaLabel,
    "mainButton-aria-describedby": mainButtonDescribedby,
    "mainButton-aria-expanded": mainButtonExpanded,
    "mainButton-aria-controls": mainButtonControls,
    mainButtonLabel,
    sideButtonLabel,
    mainButtonEverId,
    sideButtonEverId,
    sideButtonIcon = <Icon.CaretDown />,
    tabFocusable = true,
    ...props
}) => {
    const className = clsx("bb-split-icon-button", props.className, {
        "bb-split-icon-button--active": props.mainButtonActive || props.sideButtonActive,
    });
    children = React.cloneElement(children as JSX.Element, { size: ICON_SIZE.MEDIUM });
    return (
        <div className={className}>
            <IconButton
                ref={props.mainButtonRef}
                className={"bb-split-icon-button__main-button"}
                id={props.mainButtonId}
                onClick={props.onMainButtonClick}
                onMouseDown={props.onMainButtonMouseDown}
                disabled={props.disabled || props.mainButtonDisabled}
                active={props.mainButtonActive}
                label={mainButtonLabel}
                aria-label={mainButtonAriaLabel}
                aria-describedby={mainButtonDescribedby}
                aria-expanded={mainButtonExpanded}
                aria-controls={mainButtonControls}
                tabFocusable={tabFocusable}
                everId={mainButtonEverId}
                autoFocus={props.mainButtonAutoFocus}
            >
                {children}
            </IconButton>
            <IconButton
                ref={props.sideButtonRef}
                onClick={props.onSideButtonClick}
                onMouseDown={props.onSideButtonMouseDown}
                className={"bb-split-icon-button__side-button"}
                id={props.sideButtonId}
                disabled={props.disabled || props.sideButtonDisabled}
                active={props.sideButtonActive}
                label={sideButtonLabel}
                aria-label={sideButtonAriaLabel}
                aria-describedby={sideButtonDescribedby}
                aria-expanded={sideButtonExpanded}
                aria-controls={sideButtonControls}
                tabFocusable={tabFocusable}
                everId={sideButtonEverId}
                autoFocus={props.sideButtonAutoFocus}
            >
                {React.cloneElement(sideButtonIcon, { size: 16 })}
            </IconButton>
        </div>
    );
};
