import clsx from "clsx";
import { LinkProps } from "components/Text";
import { TooltipProps } from "components/Tooltip";
import { useCombinedRef } from "hooks/useCombinedRef";
import { everIdProp } from "EverAttribute/EverId";
import React, {
    ChangeEventHandler,
    cloneElement,
    forwardRef,
    KeyboardEventHandler,
    ReactElement,
    ReactNode,
    useId,
    useRef,
} from "react";
import "./ToggleSwitch.scss";
import { EverIdProp, FFC } from "util/type";
import * as CommonIcon from "components/Icon/CommonIcon";

export const TOGGLE_MAIN_LABEL_CLASS = "bb-toggle__main-label";
export const TOGGLE_SUBLABEL_CLASS = "bb-toggle__sublabel";

export interface ToggleSwitchProps extends EverIdProp {
    /**
     * The action taken when the toggle is clicked. Note that toggles do not change their `value`
     * internally, so this function should probably update `value` via a hook.
     */
    onChange: ChangeEventHandler<HTMLInputElement>;
    /**
     * An optional callback that is applied when a key is pressed while the input element is
     * focussed.
     */
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
    /**
     * If true, the toggle is switched on.
     */
    value?: boolean;
    /**
     * The label that describes the toggle switch. Required for accessibility purposes. If you don't
     * want the label to be displayed, use hideLabel.
     */
    label: ReactNode;
    /**
     * If true, do not display label.
     */
    hideLabel?: boolean;
    /**
     * Sublabel for the toggle.
     */
    subLabel?: string;
    /**
     * A learn more link to display at the end of the subLabel.
     */
    learnMoreLink?: ReactElement<LinkProps>;
    /**
     * A tooltip to render on the toggle switch. The tooltip will point to the toggle switch knob,
     * and will be triggered when the label is hovered or the input is focused.
     */
    tooltip?: ReactElement<TooltipProps>;
    /**
     * If true, the toggle has been clicked and is in the process of updating
     * (for example, waiting for a server response).
     */
    loading?: boolean;
    /**
     * If true, the toggle cannot be toggled.
     */
    disabled?: boolean;
    /**
     * Whether the toggle switch is an item in a menu component. Default false.
     */
    isMenuToggleSwitch?: boolean;
}

/**
  ToggleSwitches are on/off inputs. When a user clicks a toggle, the effect of the toggle is immediately activated.
  This is different from checkboxes, which don't do anything until a user submits their changes.
*/
export const ToggleSwitch: FFC<HTMLInputElement, ToggleSwitchProps> = forwardRef(
    (
        {
            everId,
            onChange,
            onKeyUp,
            value = false,
            label,
            hideLabel = false,
            subLabel,
            learnMoreLink,
            tooltip,
            loading = false,
            disabled = false,
            isMenuToggleSwitch = false,
        },
        ref,
    ) => {
        const inputId = useId();
        const internalInputRef = useRef<HTMLInputElement>(null);
        const inputRef = useCombinedRef(internalInputRef, ref);
        const knobRef = useRef<HTMLDivElement>(null);
        const labelRef = useRef<HTMLLabelElement>(null);
        const loadingIconRef = useRef<HTMLDivElement>(null);

        const toggleClasses = clsx("bb-toggle", {
            "bb-toggle--disabled": disabled,
            "bb-toggle--loading": loading,
        });

        const subInfoDiv = (
            <div
                id={`${inputId}__sublabel`}
                className={clsx(TOGGLE_SUBLABEL_CLASS, {
                    [TOGGLE_SUBLABEL_CLASS + "--hidden"]: hideLabel,
                })}
            >
                {subLabel && <span>{subLabel}</span>}
                {learnMoreLink}
            </div>
        );

        tooltip &&= cloneElement(tooltip, {
            id: `${inputId}__tooltip`,
            target: loading ? loadingIconRef : knobRef,
            hoverTrigger: tooltip.props.hoverTrigger || labelRef,
            focusTrigger:
                tooltip.props.focusTrigger || (loading ? loadingIconRef : internalInputRef),
        });

        const describedBy = clsx({
            [`${inputId}__sublabel`]: !isMenuToggleSwitch && (subLabel || learnMoreLink),
            [`${inputId}__tooltip`]: tooltip,
        });

        return (
            <div className={toggleClasses}>
                <input
                    id={inputId}
                    className={"bb-toggle__input"}
                    type={"checkbox"}
                    role={"switch"}
                    checked={value}
                    aria-checked={value}
                    aria-describedby={describedBy}
                    onChange={disabled || loading ? () => {} : onChange}
                    onKeyUp={disabled || loading ? undefined : onKeyUp}
                    // Most elements automatically get scrolled into view on focus.
                    // Here, the input element which receives focus is absolutely positioned,
                    // which messes with this behavior.
                    onFocus={() => labelRef.current?.scrollIntoView({ block: "nearest" })}
                    aria-disabled={disabled || loading}
                    ref={inputRef}
                />
                <label
                    ref={labelRef}
                    className={clsx("bb-toggle__label", {
                        "bb-toggle__label--hidden": hideLabel,
                    })}
                    htmlFor={inputId}
                    {...everIdProp(everId)}
                >
                    {loading ? (
                        <>
                            <div
                                ref={loadingIconRef}
                                className={"bb-toggle__loading-icon-container"}
                                tabIndex={tooltip ? 0 : undefined}
                            >
                                <CommonIcon.Loading size={20} aria-label={"Loading"} />
                            </div>
                        </>
                    ) : (
                        <div ref={knobRef} className={"bb-toggle__focus-container"}>
                            <div
                                className={
                                    value ? "bb-toggle__slider--on" : "bb-toggle__slider--off"
                                }
                            >
                                <div className={"bb-toggle__knob"} />
                            </div>
                        </div>
                    )}
                    <div className={"bb-toggle__label-content"}>
                        <div className={TOGGLE_MAIN_LABEL_CLASS}>{label}</div>
                        {(subLabel || learnMoreLink) && isMenuToggleSwitch && subInfoDiv}
                    </div>
                </label>
                {tooltip}
                {(subLabel || learnMoreLink) && !isMenuToggleSwitch && subInfoDiv}
            </div>
        );
    },
);
ToggleSwitch.displayName = "ToggleSwitch";
