import clsx from "clsx";
import * as Icon from "components/Icon";
import { Span } from "components/Text";
import { TextField, TextFieldWidth } from "components/TextInput";
import { Tooltip } from "components/Tooltip";
import { useResizeObserver } from "hooks/useResizeObserver";
import React, { FC, ReactNode, useId, useRef, useState } from "react";
import "./Cell.scss";

/**
 * This file contains reusable components for table cell content and are meant to be used with
 * the {@link Table} component.
 *
 * Any additional common cell format should be added as a component here so that it is
 * reusable and easily discoverable. It should also be added as a subcomponent of the
 * {@link Table} component. See the bottom of Table.tsx.
 */

export const EDITABLE_CELL_CLASSNAME = "bb-table__editable-cell";

export interface EditableCellProps {
    /**
     * The currently saved value for this cell. This is the string that will populate the text
     * input when the user clicks on the editable cell.
     */
    value: string;
    /**
     * An optional custom display for the currently saved value. This will be displayed in the cell
     * when the user is not currently editing. If not provided, {@link value} will be displayed.
     */
    display?: ReactNode;
    /**
     * A hidden label to apply to the text input for accessibility purposes. Usually, this should
     * be something like "Edit [column name]".
     */
    inputLabel: string;
    /**
     * The name of the object that is being edited/renamed (e.g. "Freeform Code").
     * A tooltip will be rendered on the editable cell with the text "Edit [objectName]".
     */
    objectName: string;
    /**
     * A function that is called when the user submits a valid value.
     */
    onSubmit: (value?: string) => void;
    /**
     * A callback (optionally async) that returns an error message if the given value is invalid.
     * The returned error message will be displayed under the editable cell input if the user tries
     * to submit an invalid value.
     */
    validate?: (value?: string) => string | undefined | Promise<string | undefined>;
    /**
     * Whether the row that this cell belongs to is disabled. If true, the editable cell div will
     * not be focusable. Defaults to false.
     */
    disabledRow?: boolean;
}

/**
 * A cell content component with editable text and validation. By default, the given value is
 * displayed. When clicked or selected, the value display is replaced by a text input that
 * allows the user to edit the value. The user-inputted value is validated and submitted on blur
 * or when the user hits the "Enter" key.
 */
export const EditableCell: FC<EditableCellProps> = ({
    value,
    display,
    inputLabel,
    onSubmit,
    objectName,
    validate = () => undefined,
    disabledRow = false,
}) => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const [errorMessage, setErrorMessage] = useState<string>();

    const contentRef = useRef<HTMLDivElement>(null);
    const [valueDisplayRef, valueDisplayEntry] = useResizeObserver<HTMLTableRowElement>();
    const isEllipsed =
        valueDisplayEntry.target
        && valueDisplayEntry.target.scrollWidth > valueDisplayEntry.target.clientWidth;

    const validateAndSubmit = async (value: string, submit = true) => {
        const errorMessage = await validate(value);
        setErrorMessage(errorMessage);
        if (submit && !errorMessage?.length) {
            onSubmit(value);
            setShowInput(false);
        }
    };

    const tooltipId = "editable-cell-" + useId();
    return showInput ? (
        <TextField
            value={inputValue}
            width={TextFieldWidth.FULL}
            label={inputLabel}
            hideLabel={true}
            error={!!errorMessage}
            errorMessage={errorMessage}
            autoFocus={true}
            onBlur={(e) => {
                validateAndSubmit(e.currentTarget.value);
            }}
            onChange={(e) => {
                setInputValue(e.currentTarget.value);
                if (errorMessage) {
                    validateAndSubmit(e.currentTarget.value, false);
                }
            }}
            onKeyUp={(e) => {
                // Using onKeyUp instead of onKeyDown so that validation isn't triggered
                // repeatedly if the user holds down "Enter".
                if (e.key === "Enter") {
                    validateAndSubmit(e.currentTarget.value);
                } else if (e.key === "Escape") {
                    setShowInput(false);
                    setInputValue(value);
                    // Ensure that the error state is correct when the input is next displayed.
                    validateAndSubmit(value, false);
                }
            }}
            onFocus={(e) => {
                e.currentTarget.select();
            }}
        />
    ) : (
        <div className={"bb-table__editable-cell-wrapper"}>
            <div
                role={"button"}
                ref={contentRef}
                className={EDITABLE_CELL_CLASSNAME}
                tabIndex={disabledRow ? undefined : 0}
                aria-describedby={tooltipId}
                onClick={() => setShowInput(true)}
                onKeyUp={(e) => {
                    e.key === "Enter" && setShowInput(true);
                }}
            >
                <div ref={valueDisplayRef} className={"bb-table__editable-cell-value"}>
                    {display || value}
                </div>
                <Icon.Pencil
                    className={"bb-table__editable-cell-icon"}
                    aria-label={"Press enter to edit"}
                    size={20}
                />
            </div>
            <Tooltip id={tooltipId} target={contentRef}>
                {`Edit ${objectName}`}
                {isEllipsed && ` "${display || value}"`}
            </Tooltip>
        </div>
    );
};

export interface SubtextCellProps {
    /**
     * An optional className to apply to the element.
     */
    className?: string;
    /**
     * The main value of the cell.
     */
    value: string;
    /**
     * The sub-text to display under the main value.
     */
    subText: string;
}

/**
 * A simple cell content component that displays a main value and sub-text underneath.
 */
export const SubtextCell: FC<SubtextCellProps> = ({ className, value, subText }) => {
    return (
        <div className={clsx("bb-table__subtext-cell", className)}>
            <Span>{value}</Span>
            <Span.Small className={"bb-table__subtext-cell-subtext"}>{subText}</Span.Small>
        </div>
    );
};
